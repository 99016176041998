import React, { Suspense, useEffect, memo, useContext } from "react";
import { ConfigProvider, Spin, theme } from "antd";
import classNames from "classnames";
import jwt_decode from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
// import HashLoader from "react-spinners/HashLoader";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import { HappyProvider } from '@ant-design/happy-work-theme';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import constants from "./config/constants";
import themeOverrides from "./config/themeOverrides";
import Login from "./container/pages/Auth/Login";
import MyRestoration from "./container/pages/Auth/MyRestoration";
import Register from "./container/pages/Auth/Register";
import ResetPassword from "./container/pages/Auth/ResetPassword";
import Checkout from "./container/pages/Order/Checkout";
import RedirectWithQuery from "./container/pages/Order/Partials/RedirectWithQuery";
// import Checkout from "./container/pages/Order/Checkout";
import Result from "./container/pages/Order/Result";
import Step1 from "./container/pages/Order/Step1";
import Step2 from "./container/pages/Order/Step2";
import Step3 from "./container/pages/Order/Step3";
import Success from "./container/pages/Order/Success";
import PrivateRoute from "./container/routes/PrivateRoute";
import ProtectedRoutes from "./container/routes/ProtectedRoutes";
import PublicRoute from "./container/routes/PublicRoute";
import { SocketProvider } from "./context/socket";
import { getStorage } from "./helpers";
import { logout } from "./redux/auth/authSlice";

import "./App.css";

const stripePromise = loadStripe(constants.stripePK);

const { defaultAlgorithm, darkAlgorithm } = theme;

const App = () => {

  const dispatch = useDispatch();
  const isDarkMode = useSelector(state => state.app.isDarkMode);

  useEffect(() => {
    let token = getStorage("token");
    if (token) {
      let user = jwt_decode(token);
      if (user.exp * 1000 < Date.now()) {
        dispatch(logout());
      }
    } else {
      dispatch(logout());
    }
  }, []);

  return (
    <HappyProvider>
      <ConfigProvider theme={{ ...themeOverrides, algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm }}>
        <SocketProvider>
          <Router>
            <Suspense
              fallback={
                <div className={classNames("w-screen h-screen flex items-center justify-center", isDarkMode ? "bg-gray-800" : "bg-white")}>
                  {/* <span className="loader1"></span> */}
                  <Spin />
                </div>
              }
            >
              <Routes>
                <Route exact path="/order/step1" element={<Step1 />} />
                <Route exact path="/order/step2" element={<Step2 />} />
                <Route exact path="/order/step3" element={<Step3 />} />
                <Route exact path="/order/:id?/checkout" element={<Elements options={{
                  appearance: {
                    theme: isDarkMode ? "night" : "stripe",
                  }
                }} stripe={stripePromise} nonce="random-nonce"><Checkout /></Elements>} />
                <Route exact path="/order/checkout" element={<Elements options={{
                  appearance: {
                    theme: isDarkMode ? "night" : "stripe",
                  }
                }} stripe={stripePromise} nonce="random-nonce"><Checkout /></Elements>} />
                {/* <Route exact path="/order/checkout1" element={<Elements options={{
                  appearance: {
                    theme: isDarkMode ? "night" : "stripe",
                  }
                }} stripe={stripePromise} nonce="random-nonce"><Checkout /></Elements>} /> */}
                <Route exact path="/order/success" element={<Success />} />
                <Route exact path="/orders/:id/result" element={<Result />} />
                <Route element={<PublicRoute />}>
                  <Route exact path="/login" element={<Login />} />
                  <Route exact path="/register" element={<Register />} />
                  <Route exact path="/reset-password/:token" element={<ResetPassword />} />
                  <Route exact path="/my-restoration" element={<MyRestoration />} />
                </Route>
                <Route element={<PrivateRoute />}>
                  <Route exact path="/*" element={<ProtectedRoutes />} />
                </Route>
                <Route exact path="/" element={<RedirectWithQuery to="/order/step1" />} />
                <Route exact path="*" element={<RedirectWithQuery to="/order/step1" />} />
              </Routes>
            </Suspense>
          </Router>
        </SocketProvider>
      </ConfigProvider>
    </HappyProvider>
  );
};

export default memo(App);
