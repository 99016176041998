import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Popconfirm, Radio, Row, Tabs, Typography, message } from 'antd';
import constants, { ORDER_STATUS, PHOTO_STATE, PRINT_SIZES } from '../../../../config/constants';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import { printingPackage, printingPhotoPackage, savePhotoDir, savePrintSetting } from '../../../../services/orderAPI';
import { PiFrameCornersLight } from 'react-icons/pi';
import { debounce } from 'lodash';

const { Text } = Typography;

const PrintSettingsModal = (props) => {

    const {
        isPrStModalOpen,
        setIsPrStModalOpen,
        order,
        selectedFileIndex,
        getOrder,
    } = props;

    const [landscape, setLandscape] = useState(false);
    const [saving, setSaving] = useState(false);
    const [pSetting, setPSetting] = useState([]);
    const [crp, setCrp] = useState([]);
    const [selFile, setSelFile] = useState({});

    const previewImage = (i) => {
        let se = crp[i];
        if (!se) return `${constants.SOCKET_URL}${selFile.result}`;
        let x = se.x;
        let y = se.y;
        let w = se.width;
        let h = se.height;
        let scale = se.scale;
        let searchParams = new URLSearchParams({ x, y, w, h, scale });
    
        return `${constants.SOCKET_URL}${selFile.result}?${searchParams}`;
      }
    
      const handleWheel = debounce((e, i) => {
        let tmp = [...crp];
        // console.log(e, e.deltaY, tmp[i].scale);
        tmp[i] = { ...tmp[i], scale: (tmp[i].scale ? tmp[i].scale : 1) + ((e.deltaY < 0 ? 0.01 : -0.01)) };
        if (tmp[i].scale > 1) {
          tmp[i].scale = 1;
        } else if (tmp[i].scale <= 0.3) {
          tmp[i].scale = 0.3;
        }
        setCrp(tmp);
        // e.preventDefault();
      });
    

    useEffect(() => {
        if (order && selectedFileIndex !== undefined) {
            let tmp = [], file = order.fileList[selectedFileIndex];
            // let bi = file.dimension.w / file.dimension.h;
            setLandscape(file.dimension.landscape);
            for (const i of [1, 2, 3, 4, 5]) {
                if (file[`print${i}`] > 0) {
                    // console.log(`print${i}`, file[`print${i}`])
                    tmp.push({
                        ...centerCrop(
                            makeAspectCrop(
                                {
                                    // You don't need to pass a complete crop into
                                    // makeAspectCrop or centerCrop.
                                    unit: '%',
                                    width: 100,
                                },
                                (Number(PRINT_SIZES[`print${i}`].split('x')[file.dimension.landscape ? 1 : 0]) / Number(PRINT_SIZES[`print${i}`].split('x')[file.dimension.landscape ? 0 : 1])),
                                file.dimension.w,
                                file.dimension.h
                            ),
                            file.dimension.w,
                            file.dimension.h
                        ),
                        paper: `print${i}`,
                        count: file[`print${i}`]
                    });
                }
            }
            setCrp(pre => tmp.map((item, i) => (file.print[i] ? ({ ...item, ...file.print[i] }) : item)));
            setPSetting([...tmp.map((item, i) => (file.print[i] ? ({ ...item, ...file.print[i] }) : item))]);
            setSelFile({ ...file, orderId: order._id, index: selectedFileIndex });
        }
    }, [order, selectedFileIndex]);

    return (
        <Modal
            title={<Text className='text-xl'>Print Settings</Text>}
            open={isPrStModalOpen}
            onCancel={() => { setIsPrStModalOpen(false) }}
            width={900}
            maskClosable={false}
            afterClose={() => {
                console.log('here', order.status);
                
                // Check if it is additional or not
                
                if ((order.status == ORDER_STATUS.PendingApprove && !order.fileList.filter(file => !(file.refunded > 0)).some(file => file.approved < PHOTO_STATE.Printing))) {
                    console.log('here -----------------', order);
                    // return;
                    // setPrinting(true);
                    if (order.additional) {
                        printingPhotoPackage({
                            userId: order.userId,
                            orderId: order._id,
                            fileList: order.fileList,
                            delivery: order.delivery,
                            address: order.address,
                            username: order.userId.name,
                        }).then(res => {
                            getOrder();
                        }).catch(err => {
                            console.log(err);
                            if (err.response?.data?.message)
                                message.warning(err.response.data.message);
                        }).finally(() => {
                            // setPrinting(false);
                        });
                    } else {
                        printingPackage(order._id).then(res => {
                            getOrder();
                        }).catch(err => {
                            console.log(err);
                            if (err.response?.data?.message)
                                message.warning(err.response.data.message);
                        }).finally(() => {
                            // setPrinting(false);
                        });
                    }
                }

            }}
            footer={[
                // <Button key="handleRotate1" icon={<RotateRightOutlined />} title="Rotate Photo" onClick={() => handleRotate(selFile.orderId, selFile.index, 90)}></Button>,
                // <Button key="handleRotate2" icon={<RotateLeftOutlined />} title="Rotate Photo" onClick={() => handleRotate(selFile.orderId, selFile.index, -90)}></Button>,
                <Radio.Group key='landscape' options={[
                    { label: "Landscape", value: true },
                    { label: "Portrait", value: false },
                ]} onChange={({ target: { value } }) => {
                    console.log(value);
                    setLandscape(value);
                    savePhotoDir(selFile.orderId, { index: selFile.index, landscape: value }).then(res => {
                        // console.log(res);
                        getOrder();
                    }).catch(err => console.log(err));
                    let tmp = [];
                    for (const i of [1, 2, 3, 4, 5]) {
                        if (selFile[`print${i}`] > 0) {
                            // console.log(`print${i}`, selFile[`print${i}`])
                            tmp.push({
                                ...centerCrop(
                                    makeAspectCrop(
                                        {
                                            // You don't need to pass a complete crop into
                                            // makeAspectCrop or centerCrop.
                                            unit: '%',
                                            width: 100,
                                        },
                                        (Number(PRINT_SIZES[`print${i}`].split('x')[value ? 1 : 0]) / Number(PRINT_SIZES[`print${i}`].split('x')[value ? 0 : 1])),
                                        selFile.dimension.w,
                                        selFile.dimension.h
                                    ),
                                    selFile.dimension.w,
                                    selFile.dimension.h
                                ),
                                paper: `print${i}`,
                                count: selFile[`print${i}`]
                            });
                        }
                    }
                    setCrp(pre => tmp.map((item, i) => (selFile.print[i] ? ({ ...selFile.print[i], ...item }) : item)));
                    setPSetting([...tmp.map((item, i) => (selFile.print[i] ? ({ ...selFile.print[i], ...item }) : item))]);

                }} value={landscape} optionType="button" />,
                <Popconfirm
                    title="Are you sure to print?"
                    description="Please make sure you have set up all print settings."
                    onConfirm={() => {
                        setSaving(true);
                        savePrintSetting(selFile.orderId, { index: selFile.index, print: crp }).then(async res => {
                            await getOrder();
                            message.success("Successfully saved!");
                            setIsPrStModalOpen(false);
                        }).catch(err => console.log(err)).finally(() => setSaving(false));
                    }}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button className='ml-2' key="handleOk" loading={saving}>Print</Button>
                </Popconfirm>
                ,
                <Button key="handleShow" onClick={() => {
                    setIsPrStModalOpen(false);
                }}>Close</Button>,
                // <Button key="handleCrop" danger onClick={handleCrop}>Crop</Button>
            ]}
        >
            <Tabs
                defaultActiveKey="0"
                type="card"
                size="small"

                items={[1, 2, 3, 4, 5].filter((item) => selFile[`print${item}`] > 0).map((_, i) => {
                    return {
                        label: PRINT_SIZES[`print${_}`],
                        key: i,
                        children: <Row gutter={[24, 24]}>
                            <Col span={16} onWheel={(e) => handleWheel(e, i)}>
                                <ReactCrop
                                    crop={pSetting[i]}
                                    aspect={Number(PRINT_SIZES[`print${_}`].split('x')[landscape ? 1 : 0]) / Number(PRINT_SIZES[`print${_}`].split('x')[landscape ? 0 : 1])}
                                    onComplete={(c, pc) => {
                                        // console.log(c, pc);
                                        let tmp = [...crp];
                                        tmp[i] = { ...tmp[i], ...pc };
                                        setCrp(tmp);
                                    }}
                                    className='relative w-full'
                                    data-value={'12'}
                                    onChange={(c, pc) => {
                                        // console.log(pc);
                                        let tmp = [...pSetting];
                                        tmp[i] = { ...tmp[i], ...pc };
                                        setPSetting(tmp);
                                    }}>
                                    <img className='top-0 absolute w-full mousewheel_wrapper' key={`scale(${crp[i].scale})`} src={`${constants.SOCKET_URL}${selFile.result}`} style={{
                                        transform: `scale(${crp[i].scale})`
                                    }} />
                                    <div
                                        className='bg-white mousewheel_wrapper'
                                        style={{
                                            width: "100%",
                                            aspectRatio: selFile.dimension.w / selFile.dimension.h
                                        }}
                                    >
                                    </div>
                                </ReactCrop>
                            </Col>
                            <Col span={8}>
                                <h2>Print Preview</h2>
                                <img className='border-4 border-white border-solid w-full' src={previewImage(i)} />
                                <h2 className="text-center">x {selFile[`print${_}`]}</h2>

                                <Button
                                    icon={<PiFrameCornersLight className='mr-.5' />}
                                    className='flex items-center'
                                    onClick={() => {
                                        let crop = centerCrop(
                                            makeAspectCrop(
                                                {
                                                    // You don't need to pass a complete crop into
                                                    // makeAspectCrop or centerCrop.
                                                    unit: '%',
                                                    width: 100,
                                                },
                                                (Number(PRINT_SIZES[`print${_}`].split('x')[landscape ? 1 : 0]) / Number(PRINT_SIZES[`print${_}`].split('x')[landscape ? 0 : 1])),
                                                selFile.dimension.w,
                                                selFile.dimension.h
                                            ),
                                            selFile.dimension.w,
                                            selFile.dimension.h
                                        );
                                        if (crop.height == 100) {
                                            let tmp = [...crp];
                                            // console.log(e, e.deltaY, tmp[i].scale);
                                            tmp[i] = { ...tmp[i], ...crop, scale: crop.width / 100 };
                                            if (tmp[i].scale > 1) {
                                                tmp[i].scale = 1;
                                            } else if (tmp[i].scale <= 0.3) {
                                                tmp[i].scale = 0.3;
                                            }
                                            setCrp(tmp);
                                        } else {
                                            let tmp = [...crp];
                                            // console.log(e, e.deltaY, tmp[i].scale);
                                            tmp[i] = { ...tmp[i], ...crop, scale: crop.height / 100 };
                                            if (tmp[i].scale > 1) {
                                                tmp[i].scale = 1;
                                            } else if (tmp[i].scale <= 0.3) {
                                                tmp[i].scale = 0.3;
                                            }
                                            setCrp(tmp);
                                        }
                                    }}
                                >Fit to Frame</Button>
                            </Col>
                        </Row>,
                    };
                })}
            />
        </Modal>
    );
}

export default PrintSettingsModal;
































