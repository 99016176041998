import React, { useEffect } from "react";
import { Col, Form, Input, Row } from "antd";

import { AddressElement, Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import constants from "../../../../config/constants";

const stripePromise = loadStripe(constants.stripePK);

const Address = ({ isShipped, setIsComplete, orderData, updateData, isMorePrints }) => {

    return (
        <>
            <Row gutter={[24, 12]}>
                {!isShipped &&
                    <>
                        <Col span={24}>
                            <label htmlFor="name"></label>
                            <Form.Item
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your First & Last Name!',
                                    },
                                ]}
                            >
                                <Input size="large"
                                    placeholder="First & last name"
                                    id="name"
                                    value={orderData.name}
                                    onChange={(e) => { updateData('name', e.target.value) }}
                                />
                            </Form.Item>
                        </Col>
                    </>
                }
                <Col span={24}>
                    <label htmlFor="email" className="text-base">Your Email Address</label>
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                type: 'email',
                                message: 'The input is not valid E-mail!',
                            },
                            {
                                required: true,
                                message: 'Please input your E-mail!',
                            },
                        ]}
                    // validateStatus={errors.email ? 'error' : ''}
                    // help={errors.email}
                    >
                        <Input size='large'
                            id='email'
                            placeholder="Your Best Email Address"
                            value={orderData.email}
                            disabled={isMorePrints}
                            onChange={(e) => { updateData('email', e.target.value) }}
                        />
                    </Form.Item>
                </Col>
            </Row>

            {isShipped &&
                <Elements stripe={stripePromise} nonce="random-nonce">
                    <AddressElement
                        options={{
                            mode: 'shipping',
                            defaultValues: {
                                name: orderData.name || "",
                                address: orderData.address,
                            }
                        }}

                        onChange={(e) => {
                            // console.log(e);
                            setIsComplete(e.complete);
                            updateData('name', e.value.name);
                            updateData('address', e.value.address);
                        }}
                    />
                </Elements>
            }

        </>
    );
}

export default Address;



