import React, { useEffect, useState } from 'react';
import { Modal, Button, Typography, notification, Steps, Form, message } from 'antd';

import PrintSizes from './PrintSizes';
import Address from './Address';
import DeliveryOptions from './DeliveryOptions';
import PaymentProcess from './PaymentProcess';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import constants, { ORDER_STATUS, PHOTO_STATE } from '../../../../config/constants';
import { printingPhotoPackage, updateOrder } from '../../../../services/orderAPI';

const { Text } = Typography;
const stripePromise = loadStripe(constants.stripePK);

const AdditionalPrintsModal = ({ isOpen, setIsOpen, order, user, selectedFileIndex, getOrder }) => {

    const [api, contextHolder] = notification.useNotification();

    const [form] = Form.useForm();
    const [current, setCurrent] = useState(0);
    const [isComplete, setIsComplete] = useState(false);
    const [orderData, setOrderData] = useState({});

    const onClose = () => {
        setCurrent(0);
        setIsOpen(false);
    }
    const checkPrints = () => {

        let added = orderData.fileList.some(file =>
            file.print1 > 0 ||
            file.print2 > 0 ||
            file.print3 > 0 ||
            file.print4 > 0 ||
            file.print5 > 0);

        if (!added) {
            notification.warning({
                message: "Please add at least one print size",
                showProgress: true,
            });
            return false;
        }

        return true;
    }

    const updateData = (field, value) => {
        setOrderData({ ...orderData, [field]: value });
    }

    const update = async () => {

        let orderTemp = JSON.parse(JSON.stringify(order));
        let isNeedPrintSettings = false;

        // TODO: Check refund status again
        orderData.fileList.forEach(file => {
            if (file.print1 > 0 || file.print2 > 0 || file.print3 > 0 || file.print4 > 0 || file.print5 > 0) {

                let tempFile = orderTemp.fileList.filter(orderFile => orderFile.path === file.path)[0];

                if (tempFile) {
                    // Total prints
                    tempFile.print1 = tempFile.print1 ? tempFile.print1 + file.print1 : file.print1;
                    tempFile.print2 = tempFile.print2 ? tempFile.print2 + file.print2 : file.print2;
                    tempFile.print3 = tempFile.print3 ? tempFile.print3 + file.print3 : file.print3;
                    tempFile.print4 = tempFile.print4 ? tempFile.print4 + file.print4 : file.print4;
                    tempFile.print5 = tempFile.print5 ? tempFile.print5 + file.print5 : file.print5;

                    if (order.status < ORDER_STATUS.Printing) { // This order has not printed yet
                    
                        tempFile.print = [];
                        tempFile.approved = tempFile.approved === PHOTO_STATE.Printing ? PHOTO_STATE.Approved : tempFile.approved;
                    
                    } else {
                        tempFile.additional = {
                            print1: tempFile.additional?.print1 ? tempFile.additional.print1 + file.print1 : file.print1,
                            print2: tempFile.additional?.print2 ? tempFile.additional.print2 + file.print2 : file.print2,
                            print3: tempFile.additional?.print3 ? tempFile.additional.print3 + file.print3 : file.print3,
                            print4: tempFile.additional?.print4 ? tempFile.additional.print4 + file.print4 : file.print4,
                            print5: tempFile.additional?.print5 ? tempFile.additional.print5 + file.print5 : file.print5,
                        };

                        // Check if printsetting is needed
                        if (
                            (file.print1 > 0 && tempFile.print?.filter(item => item.paper === "print1").length === 0) ||
                            (file.print2 > 0 && tempFile.print?.filter(item => item.paper === "print2").length === 0) ||
                            (file.print3 > 0 && tempFile.print?.filter(item => item.paper === "print3").length === 0) ||
                            (file.print4 > 0 && tempFile.print?.filter(item => item.paper === "print4").length === 0) ||
                            (file.print5 > 0 && tempFile.print?.filter(item => item.paper === "print5").length === 0)
                        ) {
                            order.status = ORDER_STATUS.PendingApprove;
                            tempFile.approved = PHOTO_STATE.Approved;
                            isNeedPrintSettings = true;
                        } else {
                            tempFile.print.forEach(item => {
                                if (item.paper === "print1") {
                                    item.count = tempFile.additional?.print1 || 0;
                                } else if (item.paper === "print2") {
                                    item.count = tempFile.additional?.print2 || 0;
                                } else if (item.paper === "print3") {
                                    item.count = tempFile.additional?.print3 || 0;
                                } else if (item.paper === "print4") {
                                    item.count = tempFile.additional?.print4 || 0;
                                } else if (item.paper === "print5") {
                                    item.count = tempFile.additional?.print5 || 0;
                                }
                            });
                        }
                    }
                }

            }
        });

        orderTemp.delivery = orderData.delivery;
        orderTemp.address = orderData.address;

        console.log(orderTemp);
        console.log(isNeedPrintSettings);

        if (!isNeedPrintSettings && orderTemp.status >= ORDER_STATUS.Printing) {
            printingPhotoPackage({
                userId: order.userId,
                orderId: order._id,
                fileList: orderTemp.fileList,
                delivery: orderTemp.delivery,
                address: orderTemp.address,
                username: user.name,
            }).then(res => {
                
                message.success("Successfully requested!");
                setIsOpen(false);
                getOrder();

            }).catch(err => {
                console.log(err);
                if (err.response?.data?.message)
                    message.warning(err.response.data.message);
            });
        } else {

            updateOrder(orderTemp._id, { status: ORDER_STATUS.PendingApprove, fileList: orderTemp.fileList, delivery: orderTemp.delivery, address: orderTemp.address, additional: true}).then(res => {
                api.open({
                    message: 'Print Setting Notification.',
                    description:
                      'Please set up all print settings for the photos. Click on the "Print Settings" button to set up the print settings.',
                    duration: 0,
                });

                message.success('Successfully requested!');
                setIsOpen(false);

                getOrder();
            }).catch(err => {
                console.log(err);
                message.warning("Something went wrong!");
            })
        }
    }

    const next = () => {
        if (current === 0 && !checkPrints()) {
            return;
        }

        setCurrent(current + 1);
    }

    const prev = () => {
        setCurrent(current - 1);
    }

    useEffect(() => {
        if (isOpen) {

            setCurrent(0);

            let fileList = [];
            if (selectedFileIndex === undefined) {
                order.fileList.forEach(file => {
                    fileList.push({
                        path: file.path,
                        print1: 0,
                        print2: 0,
                        print3: 0,
                        print4: 0,
                        print5: 0,
                    })
                });
            } else {
                fileList = [
                    {
                        path: order.fileList[selectedFileIndex].path,
                        print1: 0,
                        print2: 0,
                        print3: 0,
                        print4: 0,
                        print5: 0,
                    }
                ];
            }

            setOrderData({
                ...order,
                orderId: order._id,
                name: user.name,
                email: user.email,
                address: order.address,
                fileList: fileList,
                delivery: order.delivery,
            });

            form.setFieldsValue({
                name: user.name,
                email: user.email,
            });
        }
    }, [isOpen]);

    return (
        <>
            {contextHolder}
            <Modal
                title={<Text className='text-xl'>Additional Prints</Text>}
                open={isOpen}
                onCancel={onClose}
                width={900}
                maskClosable={false}
                footer={[
                    <div key='footer'>
                        {current > 0 && (
                            <Button
                                key="prev"
                                onClick={() => prev()}
                            >
                                Previous
                            </Button>
                        )}
                        {current < 3 && (
                            <Button
                                key="next"
                                type="primary"
                                onClick={() => next()}
                            >
                                Next
                            </Button>
                        )}
                        {/* {current === 3 && (
                            <Button 
                                key="order"
                                type="primary" 
                                onClick={() => message.success('Processing complete!')}
                            >
                                Order
                            </Button>
                        )} */}
                    </div>
                ]}
            >
                <Steps
                    size="small"
                    current={current}
                    items={[
                        { title: "Select Print Sizes" },
                        { title: "Address" },
                        { title: "Delivery" },
                        { title: "Payment" },
                    ]}
                    className='mt-4 mb-8'
                />
                {
                    current === 0 &&
                    <PrintSizes
                        fileList={orderData.fileList}
                        updateData={updateData}
                    />
                }
                {
                    current === 1 &&
                    <Form
                        name="register"
                        form={form}
                        className="form"
                        scrollToFirstError
                    >
                        <Address
                            isShipped={true}
                            setIsComplete={setIsComplete}
                            orderData={orderData}
                            updateData={updateData}
                            isMorePrints={true}
                        />
                    </Form>
                }
                {
                    current === 2 &&
                    <DeliveryOptions
                        initialDelivery={order.delivery}
                        updateData={updateData}
                    />
                }
                {
                    current === 3 &&
                    <Elements stripe={stripePromise} nonce="random-nonce">
                        <PaymentProcess
                            orderData={orderData}
                            isComplete={isComplete}
                            form={form}
                            isMorePrints={true}
                            updateOrder={update}
                        />
                    </Elements>
                }
            </Modal>
        </>
    );
}

export default AdditionalPrintsModal;




















