import React, { useEffect, useState } from 'react'
import { Button, Col, Divider, Input, Row, Upload, message } from 'antd';
import classNames from 'classnames';
import { PiArrowFatLeftFill, PiArrowFatRightFill } from 'react-icons/pi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RiShieldCheckFill } from "react-icons/ri";

import PublicLayout from '../../layouts/PublicLayout';
import { updateOrderDetail } from '../../../redux/order/orderSlice';

import { saveOrder } from '../../../services/orderAPI';
import PrintSizes from './Partials/PrintSizes';

function Step3() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isDarkMode = useSelector(state => state.app.isDarkMode);
  const orderData = useSelector(state => state.order);

  // const [processingDelay, setProcessingDelay] = useState(orderData.processingDelay);
  const [promo1, setPromo1] = useState(orderData.promo1);
  const [promo2, setPromo2] = useState(orderData.promo2);
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   updateData('processingDelay', processingDelay);
  // }, [processingDelay]);

  useEffect(() => {
    updateData('promo1', promo1);
  }, [promo1]);

  useEffect(() => {
    updateData('promo2', promo2);
  }, [promo2]);

  useEffect(() => {
    window.scrollTo(0, 0)
    updateData('step', 2);
  }, []);

  useEffect(() => {
    if (orderData.fileList.length == 0) {
      message.warning('Please upload your photos!');
      navigate('/order/step2');
    }
  }, [orderData]);

  const updateData = (field, value) => {
    dispatch(updateOrderDetail({
      field,
      value,
    }));
  };  

  return (
    <PublicLayout>
      {/* <Header /> */}
      <div className="mx-auto p-8 sm:p-4 w-full max-w-4xl text-center">

      <section className='my-16 text-left'>
          <Row gutter={[24, 24]}>
            <Col xs={24}>
              <div className='flex flex-wrap justify-around items-center'>
                <div className="p-2 w-2/3 sm:w-1/3 md:w-1/4">
                  <img className='rounded-xl w-full' src="/imgs/print.png" alt="" draggable={false} />
                </div>
                <div className='flex flex-col justify-center px-4 md:px-8 w-full sm:w-2/3 md:w-3/4 text-left'>
                  <h1 className='my-2 md:my-2 font-extrabold text-lg sm:text-lg md:text-xl lg:text-2xl'>Would You Like Your Restoration Printed?</h1>
                  <h1 className='flex items-center my-1 md:my-1 font-bold text-sm md:text-[16px]'><RiShieldCheckFill className='mr-2' /> Free Shipping</h1>
                  <h1 className='flex items-center my-1 md:my-1 font-bold text-sm md:text-[16px]'><RiShieldCheckFill className='mr-2' /> Museum Quality Ink</h1>
                  <h1 className='flex items-center my-1 md:my-1 font-bold text-sm md:text-[16px]'><RiShieldCheckFill className='mr-2' /> High Gloss C-Type Silver Halide Photo Paper</h1>
                  <h1 className='flex items-center my-1 md:my-1 font-bold text-sm md:text-[16px]'><RiShieldCheckFill className='mr-2' /> <span className='text-green-600'>100% Moneyback Guarantee</span></h1>
                </div>
              </div>
            </Col>
          </Row>
        </section>

        <section>
            <Row gutter={[24, 24]} className={classNames('rounded-2xl pt-6', isDarkMode ? 'bg-gray-700' : 'bg-gray-50')}>
                <Col span={24}>
                    <h1 className='font-extrabold text-center text-xl md:text-xl lg:text-2xl'>
                        Choose Your Sizes <span className='text-gray-400'>(optional)</span>
                    </h1>
                    <p className="text-center text-gray-400 text-sm md:text-[16px] italic">Select exactly how many you would like for each photo.</p>
                </Col>
                <Col span={24}>
                  <PrintSizes fileList={orderData.fileList} 
                    updateData={updateData} />
                </Col>
            </Row>
        </section>
        {/* <section className="my-16 text-white">
          <Row gutter={[24, 24]} className='relative bg-cover py-6 rounded-2xl' align={"center"} style={{
            backgroundImage: `url('${dbg}')`
          }}>
            <div className="top-0 left-0 absolute bg-[#000a] rounded-2xl w-full h-full"></div>
            <Col md={20} xs={24}>
              <h1 className='font-bold text-xl md:text-3xl'>
                Want to add frames for your prints?
              </h1>
              <p className="mb-0 font-gray-400 text-md md:text-xl">You can browse our selection of frames and find the perfect one to complete your prints after you approve the digital finished versions of your photos. Continue below for now!</p>
            </Col>
          </Row>
        </section> */}
        <Divider className='mt-12' />
        {/* <section className="my-16">
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <h1 className='font-extrabold text-center text-xl md:text-2xl lg:text-3xl'>We deliver from Monday to Saturday</h1>
            </Col>
            <Col xs={24} md={12}>
              <div 
                onClick={() => setProcessingDelay(0)}
                className={
                  classNames('border-2 border-solid py-2 sm:py-3 md:py-6 px-4 rounded-lg relative h-full', 
                    processingDelay == 0 && 'border-orange-500', 
                    processingDelay != 0 && isDarkMode && "border-gray-700",
                    processingDelay != 0 && !isDarkMode && "border-gray-300",
                  )
                }
              >
                <div className='top-5 md:right-5 left-5 md:left-auto absolute'>
                  <CheckCircleTwoTone className='text-lg' twoToneColor={processingDelay == 0 ? '#0F0' : '#AAA'} />
                </div>
                <div className='flex flex-col justify-between items-center pt-4 h-full'>
                  <img src="/imgs/delivery-motorbike-svgrepo-com.svg" className='w-12 sm:w-14 md:w-16' alt="" />
                  <h1 className={
                    classNames('text-xl sm:text-2xl md:text-4xl font-bold my-3', 
                      processingDelay == 0 ? (isDarkMode ? 'text-gray-200' : 'text-gray-600') : (isDarkMode ? 'text-gray-400' : 'text-gray-400')
                    )}>
                    3 Days
                  </h1>
                  <h2 className='text-orange-500 text-sm sm:text-lg md:text-xl'>NO EXTRA CHARGE</h2>
                  <p className='font-bold'>{dayjs().add(3, 'day').format('dddd MMMM D')}</p>
                </div>
              </div>
            </Col>
            <Col xs={24} md={12}>
              <div 
                onClick={() => setProcessingDelay(1)}
                className={
                  classNames('border-2 border-solid py-2 sm:py-3 md:py-6 px-4 rounded-lg relative h-full', 
                    processingDelay == 1 && 'border-orange-500', 
                    processingDelay != 1 && isDarkMode && "border-gray-700",
                    processingDelay != 1 && !isDarkMode && "border-gray-300",
                  )
                }
              >
                <div className='top-5 md:right-5 left-5 md:left-auto absolute'>
                  <CheckCircleTwoTone className='text-lg' twoToneColor={processingDelay == 1 ? '#0F0' : '#AAA'} />
                </div>
                <span className="top-0 left-1/2 absolute bg-orange-500 px-2 py-1 rounded-t font-bold text-white italic -translate-x-1/2 -translate-y-full">Most Popular</span>
                <div className='flex flex-col justify-between items-center pt-4 h-full'>
                  <img src="/imgs/airplane-delivery-svgrepo-com.svg" className='w-16 sm:w-14 md:w-20' alt="" />
                  <h1 className={classNames('text-xl sm:text-2xl md:text-4xl font-bold my-3', processingDelay == 1 ? (isDarkMode ? 'text-gray-200' : 'text-gray-600') : (isDarkMode ? 'text-gray-400' : 'text-gray-400'))}>24 Hours</h1>
                  <h2 className='text-orange-500 text-sm sm:text-lg md:text-xl'>$9.99 PER PHOTO</h2>
                  <p className='font-bold'>{dayjs().add(1, 'day').format('dddd MMMM D')}</p>
                </div>
              </div>
            </Col>
          </Row>
        </section> */}

        {/* <section className="my-16 text-white">
          <Row gutter={[24, 24]} className='relative bg-cover py-6 rounded-2xl' align={"center"} style={{
            backgroundImage: `url('${dbg}')`
          }}>
            <div className="top-0 left-0 absolute bg-[#000a] rounded-2xl w-full h-full"></div>
            <Col md={20} xs={24}>
              <h1 className='font-bold text-xl md:text-3xl'>
                How quickly would you like to receive your restorations?
              </h1>
              <p className="mb-0 font-gray-400 text-md md:text-xl">We deliver from Monday to Saturday.</p>
            </Col>
          </Row>
        </section> */}

        {/* <section className="my-16">
          <Row gutter={[24, 24]} align={'center'}>
            <Col xs={24} md={8}>
              <Card onClick={() => setProcessingDelay(0)} className={classNames('h-full cursor-pointer border-4 border-solid hover:shadow-lg relative', (processingDelay == 0 && isDarkMode) && 'border-purple-700 bg-gray-900', (processingDelay == 0 && !isDarkMode) && "border-purple-700 bg-purple-50", (processingDelay != 0 && "border-transparent"))} styles={{ body: { height: '100%' } }}>
                <div className='top-5 md:right-5 left-5 md:left-auto absolute'><CheckCircleTwoTone className='text-lg' twoToneColor={processingDelay == 0 ? '#0F0' : '#AAA'} /></div>
                <div className='flex flex-col justify-between items-center pt-4 h-full'>
                  <img src="/imgs/delivery-motorbike-svgrepo-com.svg" className='w-12 sm:w-14 md:w-16' alt="" />
                  <h1 className={classNames('text-xl sm:text-2xl md:text-4xl font-bold my-3', processingDelay == 0 ? (isDarkMode ? 'text-gray-200' : 'text-gray-600') : (isDarkMode ? 'text-gray-400' : 'text-gray-400'))}>3 Days</h1>
                  <h2 className='text-orange-500 text-sm sm:text-lg md:text-xl'>NO EXTRA CHARGE</h2>
                  <p className='font-bold'>{dayjs().add(3, 'day').format('dddd MMMM D')}</p>
                </div>
              </Card>
            </Col>
            <Col xs={24} md={8}>
              <Card onClick={() => setProcessingDelay(1)} className={classNames('h-full cursor-pointer border-4 border-solid hover:shadow-lg relative', (processingDelay == 1 && isDarkMode) && 'border-purple-700 bg-gray-900', (processingDelay == 1 && !isDarkMode) && "border-purple-700 bg-purple-50", (processingDelay != 1 && "border-transparent"))} styles={{ body: { height: '100%' } }}>
                <div className='top-5 md:right-5 left-5 md:left-auto absolute'><CheckCircleTwoTone className='text-lg' twoToneColor={processingDelay == 1 ? '#0F0' : '#AAA'} /></div>
                <div className='top-0 left-[50%] absolute bg-purple-700 py-0.5 rounded-full w-44 font-bold text-sm text-white -translate-x-1/2 -translate-y-1/2'>MOST POPULAR</div>
                <div className='flex flex-col justify-between items-center pt-4 h-full'>
                  <img src="/imgs/airplane-delivery-svgrepo-com.svg" className='w-16 sm:w-14 md:w-20' alt="" />
                  <h1 className={classNames('text-xl sm:text-2xl md:text-4xl font-bold my-3', processingDelay == 1 ? (isDarkMode ? 'text-gray-200' : 'text-gray-600') : (isDarkMode ? 'text-gray-400' : 'text-gray-400'))}>24 Hours</h1>
                  <h2 className='text-orange-500 text-sm sm:text-lg md:text-xl'>$9.99 PER PHOTO</h2>
                  <p className='font-bold'>{dayjs().add(1, 'day').format('dddd MMMM D')}</p>
                </div>
              </Card>
            </Col> */}
            {/* <Col xs={24} md={8}>
              <Card onClick={() => setProcessingDelay(2)} className={classNames('h-full cursor-pointer border-4 border-solid hover:shadow-lg relative', (processingDelay == 2 && isDarkMode) && 'border-purple-700 bg-gray-900', (processingDelay == 2 && !isDarkMode) && "border-purple-700 bg-purple-50", (processingDelay != 2 && "border-transparent"))} styles={{ body: { height: '100%' } }}>
                <div className='top-5 md:right-5 left-5 md:left-auto absolute'><CheckCircleTwoTone className='text-lg' twoToneColor={processingDelay == 2 ? '#0F0' : '#AAA'} /></div>
                <div className='flex flex-col justify-between items-center pt-4 h-full'>
                  <img src="/imgs/delivery3.svg" className='w-12 sm:w-14 md:w-16' alt="" />
                  <h1 className={classNames('text-xl sm:text-2xl md:text-4xl font-bold my-3', processingDelay == 2 ? (isDarkMode ? 'text-gray-200' : 'text-gray-600') : (isDarkMode ? 'text-gray-400' : 'text-gray-400'))}>24 Hours</h1>
                  <h2 className='text-orange-500 text-sm sm:text-lg md:text-xl'>$19.97 PER PHOTO</h2>
                  <p className='font-bold'>{dayjs().add(1, 'day').format('dddd MMMM D')}</p>
                </div>
              </Card>
            </Col> */}
          {/* </Row>
        </section> */}

        <section className='mt-4 mb-8'>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <div className="flex flex-wrap justify-center items-center">
                <Button className='inline-flex justify-center items-center m-2 w-full sm:w-44' size='large' onClick={() => {
                  updateData('step', 1);
                  navigate('/order/step2');
                }}><PiArrowFatLeftFill /> &nbsp; Back</Button>
                <Button onClick={() => {
                  setLoading(true);
                  saveOrder({ orderData }).then(res => {
                    // console.log(res);
                    updateData("orderId", res.data.orderId);
                    navigate('/order/checkout');
                  }).catch(err => {
                    console.log(err);
                  }).finally(() => {
                    setLoading(false);
                  });
                }} className='inline-flex justify-center items-center m-2 w-full sm:w-96' size='large' type='primary' loading={loading}>Click to go to Checkout &nbsp; <PiArrowFatRightFill /></Button>
              </div>
            </Col>
          </Row>
        </section>
      </div>
    </PublicLayout>
  )
}

export default Step3;