import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Divider, Form, Input, Row, message } from 'antd';
import { PiArrowFatRightFill } from "react-icons/pi";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import PublicLayout from '../../layouts/PublicLayout';
import { updateOrderDetail } from '../../../redux/order/orderSlice';

import { saveUser } from '../../../services/authAPI';
import { checkPromo } from '../../../services/planAPI';

function Step1() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const orderData = useSelector(state => state.order);

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    checkPromo(location.search).then(({ data }) => {
      updateData('coupon', data.coupon);
    }).catch(err => console.log(err)).finally(() => {
    });
    window.scrollTo(0, 0);
    updateData('step', 0);
  }, []);

  // useEffect(() => {
  //   if (orderData.name && orderData.email) {
  //     onFinish({ name: orderData.name, email: orderData.email });
  //   }
  // }, [orderData]);

  useEffect(() => {
    // updateData('option', option);
    updateData('fileList', []);
  }, []);

  const updateData = (field, value) => {
    dispatch(updateOrderDetail({ field, value }));
  }

  const onFinish = (values) => {
    setLoading(true);
    saveUser(values, location.search).then(({ data }) => {
      updateData('step', 1);
      updateData('coupon', data.coupon);
      navigate('/order/step2');
    }).catch(err => {
      console.log(err);
      message.error("Something went wrong!");
    }).finally(() => {
      setLoading(false);
    });
  }

  return (
    <PublicLayout>
      {/* <Header /> */}
      <div className="mx-auto p-4 w-full max-w-4xl text-center">
        <Form
          name="register-user"
          form={form}
          className="form"
          scrollToFirstError
          initialValues={{
            name: orderData.name,
            email: orderData.email,
          }}
          onFinish={onFinish}
        >
          <section className='my-8 sm:my-16 text-left'>
            <Row gutter={[12, 12]}>
              <Col span={24}>
                <h1 className='text-lg md:text-xl lg:text-2xl'>First & Last Name</h1>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your First & Last Name!',
                    },
                  ]}
                >
                  <Input size='large' placeholder='First & last name' value={orderData.name} onChange={(e) => updateData('name', e.target.value)} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <h1 className='text-lg md:text-xl lg:text-2xl'>Email</h1>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      type: 'email',
                      message: 'The input is not valid E-mail!',
                    },
                    {
                      required: true,
                      message: 'Please input your E-mail!',
                    },
                  ]}
                >
                  <Input size="large" placeholder='Your Email Address' value={orderData.email} onChange={(e) => updateData('email', e.target.value)} />
                </Form.Item>
              </Col>
            </Row>
          </section>
          
          <Divider className='my-8 sm:my-16' />
          {/* <section className="my-4">
            <Row gutter={[12, 12]} justify="center" className='items-stretch'>
              <Col span={24}>
                <h1 className='mx-auto max-w-2xl text-xl md:text-2xl lg:text-3xl'>Would you like to upload your photos now?</h1>
              </Col>
              <Col xs={22} sm={12} md={8}>
                <Card onClick={() => setOption('ready')} className={classNames('h-full cursor-pointer border-4 border-solid hover:shadow-lg relative', (option == 'ready' && isDarkMode) && 'border-purple-700 bg-gray-900', (option == 'ready' && !isDarkMode) && "border-purple-700 bg-purple-50", (option != "ready" && "border-transparent"))} styles={{ body: { height: '100%' } }}>
                  <div className='top-5 sm:right-5 left-5 sm:left-auto absolute'><CheckCircleTwoTone style={{
                    fontSize: 20
                  }} twoToneColor={option == 'ready' ? '#0F0' : '#AAA'} /></div>
                  <div className='flex flex-col justify-between items-center pt-4 h-full'>
                    <h2>I'm ready to upload them!</h2>
                    <img src="/imgs/photo-camera-svgrepo-com.svg" alt="ready" className='w-20' />
                    <p>You will be asked to upload your photos on Step 2</p>
                  </div>
                </Card>
              </Col>
              
            </Row>
          </section>
          <Divider className='my-16' /> */}
          <section className='mt-4 mb-8'>
            <Row gutter={[12, 12]}>
              <Col span={24}>
                <Button htmlType='submit' className='inline-flex justify-center items-center w-96 max-w-full' size='large' type='primary' loading={loading}>Click to go to Step 2 &nbsp; <PiArrowFatRightFill /></Button>
              </Col>
            </Row>
          </section>
        </Form>
      </div>
    </PublicLayout>
  )
}

export default Step1;