import { lazy } from 'react';

const routes = [
  {
    path: 'home',
    component: lazy(() => import('../pages/Home/Home')),
    // isPayed: true,
    exact: true,
  },
  {
    path: 'my-restorations',
    component: lazy(() => import('../pages/MyRestoration')),
    // isPayed: true,
    exact: true,
  },
  {
    path: 'products/:category',
    component: lazy(() => import('../pages/Home/Products')),
    exact: true,
  },
  {
    path: 'carts',
    component: lazy(() => import('../pages/Home/Cart')),
    exact: true,
  },
  {
    path: 'carts/checkout',
    component: lazy(() => import('../pages/Home/Checkout/CheckoutWrapper')),
    exact: true,
  },
  // {
  //   path: 'plans',
  //   component: lazy(() => import('../pages/Price/Lists')),
  //   exact: true,
  // },
  // {
  //   path: 'plans/:slug',
  //   component: lazy(() => import('../pages/Price/Subscription')),
  //   exact: true,
  // },
  {
    path: 'artist/orders',
    component: lazy(() => import('../pages/Artist/Users')),
    exact: true,
    isArtist: true,
  },
  {
    path: 'artist/:id/orders',
    component: lazy(() => import('../pages/Admin/Orders')),
    exact: true,
    isArtist: true,
  },
  {
    path: '/users/:id/orders',
    component: lazy(() => import('../pages/Admin/Orders')),
    exact: true,
  },
  {
    path: 'user/profile',
    component: lazy(() => import('../pages/Profile/Edit')),
    exact: true,
  },
  {
    path: 'admin/products',
    component: lazy(() => import('../pages/Admin/Products')),
    exact: true,
    isAdmin: true,
  },
  {
    path: 'admin/users',
    component: lazy(() => import('../pages/Admin/Users')),
    exact: true,
    isAdmin: true,
  },
];

export default routes;
