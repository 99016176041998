import React, { useEffect, useState } from 'react';
import { Alert, Button, Flex, Image, Table, Typography } from 'antd';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import PublicLayout from '../../layouts/PublicLayout';
import img from "../../../assets/images/payment.png";
import imgDark from "../../../assets/images/paymentDark.png";
import constants, { SHIPPING_PRICING } from '../../../config/constants';
import { updateOrderDetail } from '../../../redux/order/orderSlice';
import { getPromo } from '../../../helpers';

const { Text } = Typography;

const Success = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const orderData = useSelector(state => state.order);
  const isDarkMode = useSelector(state => state.app.isDarkMode);

  const [dataSource, setDataSource] = useState([]);

  const columns = [
    {
      title: "PRODUCT",
      dataIndex: "product",
      key: "product",
    },
    {
      title: "GTY",
      dataIndex: "gty",
      key: "gty",
    },
    {
      title: "PRICE",
      dataIndex: "price",
      key: "price",
    }
  ];

  const isShipped = () => {
    return orderData.promo1 || orderData.promo2 || orderData.fileList.some(file => file.print1 > 0 || file.print2 > 0 || file.print3 > 0 || file.print4 > 0 || file.print5 > 0);
  }

  const totalPrice = () => {
    let price = orderData.fileList.reduce((accumulator, file) => {
      let subPrice = 0;
      subPrice += Number(file.colorize || 0) * 9.99;
      subPrice += (file.print1 || 0) * 10;
      subPrice += (file.print2 || 0) * 15;
      subPrice += (file.print3 || 0) * 24;
      subPrice += (file.print4 || 0) * 34;
      subPrice += (file.print5 || 0) * 49;
      return accumulator + subPrice;
    }, 0);

    let rPrice = orderData.artist == "top" ? 58 : 38;
    if (orderData.coupon == 'static') rPrice = 22;
    price += orderData.fileList.length * rPrice;
    price += orderData.promo1 ? (orderData.fileList.length * 29) : 0;
    price += orderData.promo2 ? (orderData.fileList.length * 19) : 0;
    price += orderData.fileList.length * (orderData.processingDelay > 0 ? Number(`${orderData.processingDelay - 1}9.99`) : 0);
    
    if (isShipped()) {
      price += SHIPPING_PRICING[orderData.delivery].price;
    }
    if (orderData.fileList.length > 2 && orderData.fileList.length < 5) {
      price -= 20;
    } else if (orderData.fileList.length > 4 && orderData.fileList.length < 10) {
      price -= 40;
    } else if (orderData.fileList.length > 9) {
      price -= 100;
    }
    return [Number((price * (getPromo(orderData.coupon))).toFixed(2)), Number((price + (orderData.coupon == 'static' ? (16 * orderData.fileList.length) : 0)).toFixed(2))];
  }

  useEffect(() => {
    // updateData("fileList", []);
    // updateData("promo1", false);
    // updateData("promo2", false);
    updateData("note", "");
    updateData("orderId", "");
    updateData("coupon", "");
    // updateData("delivery", "free");
    window.scrollTo(0, 0);
  }, []);

  const updateData = (field, value) => {
    dispatch(updateOrderDetail({ field, value }));
  }

  useEffect(() => {
    if (orderData) {
      let dataSourceTemp = [];

      dataSourceTemp.push({
        key: "description",
        product: 
          <Flex vertical>
            <Text className='text-lg'>
              Photo restoration artist:
              { orderData.artist == 'top' ? " Head Artist" : " Experienced Artist" }
            </Text>
            <Text className='text-lg'>
              Processing speed:
              { orderData.processingDelay == 1 ? " 24 Hours" : " 3 Days" }
            </Text>
            {
              isShipped() && 
                <Text className='text-lg'>
                  Shipping speed:
                  { " " + SHIPPING_PRICING[orderData.delivery].title }
                </Text>
            }
            {
              orderData.fileList.length > 2 && 
                <Text className='text-lg'>
                  Discount: {
                    " " + orderData.fileList.length >= 10 ? "10 Photos" : orderData.fileList.length >= 5 ? "5 Photos" : "3 Photos"
                  }
                </Text>
            }
          </Flex>,
        gty: <Flex vertical>
          <Text className='text-lg'>1</Text>
          <Text className='text-lg'>1</Text>
          { isShipped() && <Text className='text-lg'>1</Text> }
          {
            orderData.fileList.length > 2 && 
              <Text className='text-lg'>1</Text>
          }
        </Flex>,
        price: <Flex vertical>
          <Text className='text-lg'>
            {
              orderData.coupon === 'static' && <span className='line-through'>${orderData.fileList.length * 38}</span> + " "
            }
             $
            { orderData.fileList.length * (orderData.coupon == "static" ? 22 : 38) }
          </Text>
          <Text className='text-lg'>
            ${
              Number(orderData.fileList.length * (orderData.processingDelay > 0 ? Number(`${orderData.processingDelay - 1}9.99`) : 0)).toFixed(2)
            }
          </Text>
          {
            isShipped() && 
            <Text className='text-lg'>${Number(SHIPPING_PRICING[orderData.delivery].price).toFixed(2)}</Text>
          }
          {
            orderData.fileList.length > 2 && 
              <Text className='text-lg'>
                {
                  orderData.fileList.length >= 10 ? "-$100" : orderData.fileList.length >= 5 ? "-$40" : "-$20"
                }
              </Text>
          }
        </Flex>,
      });

      <p className='my-1'><span className="font-bold">Photo restoration artist:</span> {orderData.artist == 'top' ? "Head Artist" : "Experienced Artist"}</p>

      orderData.fileList.forEach((file, index) => {
        // if (file.colorize > 0 || file.print1 > 0 || file.print2 > 0 || file.print3 > 0 || file.print4 > 0 || file.print5 > 0) {
          dataSourceTemp.push({
            key: index,
            product: 
              <Flex>
                <div className='w-40 h-40'>
                  <Image src={`${constants.SOCKET_URL}${file.path}`}
                    alt="Photo" 
                    width={"100%"}
                    height={"100%"}
                    className="object-cover"
                  />
                </div>
                <Flex vertical className="ml-4" justify='center'>
                  { file.colorize && <Text className='text-lg'>Colorization: Yes</Text> }
                  { file.print1 > 0 && <Text className='text-lg'>4x6 Print: Yes</Text> }
                  { file.print2 > 0 && <Text className='text-lg'>5x7 Print: Yes</Text> }
                  { file.print3 > 0 && <Text className='text-lg'>8x10 Print: Yes</Text> }
                  { file.print4 > 0 && <Text className='text-lg'>11x14 Print: Yes</Text> }
                  { file.print5 > 0 && <Text className='text-lg'>16x20 Print: Yes</Text> }
                </Flex>
              </Flex>,
            gty: 
              <Flex vertical>
                { file.colorize && <Text className='text-lg'>1</Text> }   
                { file.print1 > 0 && <Text className='text-lg'>{ file.print1 }</Text> }
                { file.print2 > 0 && <Text className='text-lg'>{ file.print2 }</Text> }
                { file.print3 > 0 && <Text className='text-lg'>{ file.print3 }</Text> }
                { file.print4 > 0 && <Text className='text-lg'>{ file.print4 }</Text> }
                { file.print5 > 0 && <Text className='text-lg'>{ file.print5 }</Text> }
              </Flex>,
            price: 
              <Flex vertical>
                { file.colorize && <Text className='text-lg'>$9.99</Text> }
                { file.print1 > 0 && <Text className='text-lg'>${ file.print1 * 10 }</Text> }
                { file.print2 > 0 && <Text className='text-lg'>${ file.print2 * 15 }</Text> }
                { file.print3 > 0 && <Text className='text-lg'>${ file.print3 * 24 }</Text> }
                { file.print4 > 0 && <Text className='text-lg'>${ file.print4 * 34 }</Text> }
                { file.print5 > 0 && <Text className='text-lg'>${ file.print5 * 49 }</Text> }
              </Flex>,
          })
        // }
      });

      setDataSource(dataSourceTemp);

    }
  }, [orderData]);

  return (
    <PublicLayout>
      <img src="https://fixphotos.ai/split-test-for-elementor/v1/tests/2/track-conversion/" alt="" className='w-0 h-0' />
      <img src="https://fixphotos.ai/split-test-for-elementor/v1/tests/4/track-conversion/" alt="" className='w-0 h-0' />

      <Flex justify='center'>
        <div className='m-5 max-w-5xl'>
          <Alert
            message="Your order was successful!"
            type="success"
            showIcon
            className="mx-auto w-96 text-base"
          />
          <Flex className='my-24'
            align='center'
            gap="24px"
          >
            <Flex vertical>
              <div className='mb-24'>
                <Text className='text-lg'>
                  To check the status of your order, request revisions, or approve the proof please visit this link:
                </Text>
                <br />
                <a href='https://upload.fixphotos.ai/my-restorations' 
                  target='_blank'
                  className='text-blue-500 text-lg underline'
                >
                    https://upload.fixphotos.ai/my-restorations
                </a>
                <br />
                <Button className='mt-8 w-72'
                  type='primary'
                  size='large'
                  onClick={() => { navigate('/my-restorations') }}
                >
                  Visit My Restorations
                </Button>
              </div>
              <Text className='text-lg'>
                Please save this link and check it before contacting support for the status of your order. Most restorations are complete in 48-72 hours.
              </Text>
            </Flex>

            <Image src={isDarkMode ? imgDark : img}
              className="md:block hidden mx-auto w-full h-auto"
              preview={false}
              draggable={false}
            />
          </Flex>

          <Flex justify='center'>
            <Text className='mb-4 font-bold text-2xl'>
              Your Order Details{" ("}
              {
                orderData.coupon && <span className='font-bold line-through'>${totalPrice()[1]}</span>
              }
                ${totalPrice()[0] + ")"}
            </Text>
          </Flex>

          <Table 
            dataSource={dataSource}
            columns={columns}
          />
        </div>
      </Flex>
    </PublicLayout>
  )
}

export default Success;