
const themeOverrides = {
  token: {
    colorPrimary: "#273E52",
  },
  components: {
    Button: {
      // colorPrimary: "#273E52",
      borderRadiusLG: 0,
    },
  },
}

export default themeOverrides;