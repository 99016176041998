import { getRequest, postRequest, putRequest } from "./axiosClient";

export const getUserSubscription = () => getRequest('plans/getUserSubscription');
export const cancelSubscription = (data) => putRequest('plans/cancelSubscription', data);
export const continueSubscription = (data) => putRequest('plans/continueSubscription', data);
export const createSetupIntent = (data) => postRequest('plans/createSetupIntent', data);
export const createSubscription = (data) => postRequest('plans/createSubscription', data);
export const createPaymentIntent = (data) => postRequest("plans/create-payment-intent", data);
export const createCartPaymentIntent = (data) => postRequest("plans/create-cart-payment-intent", data);
export const charge = (data) => postRequest("plans/charge", data);
export const refund = (id, data) => postRequest(`plans/refund/${id}`, data);
export const refundFile = (id, data) => postRequest(`plans/refundFile/${id}`, data);
export const orderWithPayPal = (id, data) => postRequest(`plans/${id}`, data);
export const getOrderStatus = (id) => postRequest(`plans/${id}/capture`);
export const purchaseWithPayPal = (data) => postRequest(`plans/purchase`, data);
export const getPurchaseStatus = () => postRequest(`plans/purchase/capture`);
export const checkPromo = (search) => getRequest(`plans/checkPromo${search}`);