import { useState } from "react";
import { Button, Card, Flex, Form, Input, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";

import PublicLayout from "../../layouts/PublicLayout";
import { UserOutlined } from "@ant-design/icons";
import { guestLogin } from "../../../redux/auth/authSlice";

function MyRestoration() {

    const dispatch = useDispatch();
    const errors = useSelector(state => state.auth.errors);
    const loader = useSelector(state => state.auth.loader);

    const [email, setEmail] = useState('');

    const onGuestLogin = () => {
        dispatch(guestLogin({email}));
    }

    return (
        <PublicLayout>
            <div style={{ minHeight: "calc(100vh - 135px)" }} className="flex flex-col justify-center items-center -mt-10">
                <Flex justify="center" align="center">
                    <Card className="shadow-lg w-[400px]">
                        <Form>
                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input your Email!",
                                    },
                                ]}
                                validateStatus={errors.email ? "error" : ""}
                                help={errors.email}
                            >
                                <Input size="large" 
                                    prefix={<UserOutlined className="site-form-item-icon" />} type="email"
                                    placeholder="Enter Your Order Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    autoComplete="username" 
                                />
                            </Form.Item>

                            <Form.Item>
                                <Button loading={loader} type="primary" htmlType="submit" className="w-full" size="large"
                                    onClick={onGuestLogin}
                                >
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </Flex>
            </div>
        </PublicLayout>
    );
}

export default MyRestoration;