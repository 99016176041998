import React, { useEffect, useState } from 'react';
import { Button, message } from 'antd';
import classNames from 'classnames';
import { FaRegCircle, FaRegCircleDot } from 'react-icons/fa6';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { LockOutlined } from '@ant-design/icons';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import constants, { SHIPPING_PRICING } from '../../../../config/constants';
import { getPromo } from '../../../../helpers';

import { createPaymentIntent, getOrderStatus, orderWithPayPal } from '../../../../services/planAPI';

const PaymentProcess = ({
    orderData, isComplete, form, isMorePrints, updateOrder
}) => {

    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const isDarkMode = useSelector(state => state.app.isDarkMode);

    const [loading, setLoading] = useState(false);
    const [payment, setPayment] = useState('card');
    const [cardError, setCardError] = useState("");

    const isShipped = () => {
        return orderData.fileList.some(file => file.print1 > 0 || file.print2 > 0 || file.print3 > 0 || file.print4 > 0 || file.print5 > 0);
    }

    const totalPrice = () => {
        let price = orderData.fileList.reduce((accumulator, file) => {
            let subPrice = 0;
            subPrice += Number(file.colorize || 0) * 9.99;
            subPrice += (file.print1 || 0) * 10;
            subPrice += (file.print2 || 0) * 15;
            subPrice += (file.print3 || 0) * 24;
            subPrice += (file.print4 || 0) * 34;
            subPrice += (file.print5 || 0) * 49;
            return accumulator + subPrice;
        }, 0);

        if (!isMorePrints) {
            let rPrice = orderData.artist == "top" ? 58 : 38;
            if (orderData.coupon == 'static') rPrice = 22;
            price += orderData.fileList.length * rPrice;

            price += orderData.fileList.length * (orderData.processingDelay > 0 ? Number(`${orderData.processingDelay - 1}9.99`) : 0);

            // Discount
            if (orderData.fileList.length > 2 && orderData.fileList.length < 5) {
                price -= 20;
            } else if (orderData.fileList.length > 4 && orderData.fileList.length < 10) {
                price -= 40;
            } else if (orderData.fileList.length > 9) {
                price -= 100;
            }
        }

        if (isShipped()) {
            price += SHIPPING_PRICING[orderData.delivery].price;
        }

        return [Number((price * (getPromo(orderData.coupon))).toFixed(2)), Number((price + (orderData.coupon == 'static' ? (16 * orderData.fileList.length) : 0)).toFixed(2))];
    }

    const payNow = async () => {

        if (loading) return;
        if (isShipped() && !isComplete) {
            return message.warning("Please fill all billing info!");
        }
        form.validateFields().then(async (values) => {
            try {
                setLoading(true);
                let paymentMethod = await stripe?.createPaymentMethod({
                    type: "card",
                    card: elements.getElement(CardElement),
                    billing_details: {
                        name: orderData.name,
                        email: orderData.email,
                    },
                });

                if (paymentMethod?.error) {
                    setCardError(paymentMethod.error.message);
                    setLoading(false);
                    return;
                }
                let res = await createPaymentIntent({
                    paymentMethod: paymentMethod?.paymentMethod?.id,
                    pm_type: paymentMethod?.paymentMethod?.card.brand,
                    pm_last_four: paymentMethod?.paymentMethod?.card.last4,
                    orderData,
                    isMorePrints,
                });

                const { paymentIntent, error } = await stripe.confirmCardPayment(res.data.clientSecret);

                if (error) {
                    console.log(error, '-----------payment----------');
                    setLoading(false);
                    return message.error(error.message);
                }

                let paymentId = paymentIntent.id;

                window.gtag('event', 'conversion', {
                    'send_to': 'AW-11469018582/7t47CKHFiY4ZENar7dwq',
                    'transaction_id': paymentId,
                });

                message.success("Successsfully purchased!");
                setLoading(false);

                if (isMorePrints) {
                    updateOrder();
                }

            } catch (error) {
                console.log(error);
                setLoading(false);
                if (error.response?.data?.message) {
                    message.error(error.response.data.message);
                } else if (error.message) {
                    message.error(error.message);
                }
            }
        }).catch(err => { console.log(err) });
    }

    const createOrder = (data, actions) => {
        return orderWithPayPal(orderData.orderId, { orderData, isMorePrints }).then(res => {
            console.log(res);
            return res.data.result.id
        });
    }

    // check Approval
    const onApprove = (data, actions) => {
        return getOrderStatus(orderData.orderId).then(res => {
            let paymentId = res.data.result.id;
            window.gtag('event', 'conversion', {
                'send_to': 'AW-11469018582/7t47CKHFiY4ZENar7dwq',
                'transaction_id': paymentId,
            });
            message.success("Successsfully purchased!");

            if (isMorePrints) {
                updateOrder();
            }
            
            if (res.data.orderId) {
                navigate(`/order/${res.data.orderId}/result`);
            } else {
                navigate('/order/success');
            }
        });
    }

    useEffect(() => {
      console.log(orderData);
    }, [orderData]);
    
    return (
        <>
            <table className="rtl:text-right w-full text-gray-500 text-left text-sm dark:text-gray-400">
                <thead className={classNames("text-md", isDarkMode ? "bg-gray-700 text-gray-300" : "text-gray-700 bg-gray-200")}>
                    <tr>
                        <th scope="col" className="px-6 py-5">
                            PRODUCT
                        </th>
                        <th scope="col" className="px-6 py-5 text-center">
                            GTY
                        </th>
                        <th scope="col" className="text-right px-6 py-5">
                            PRICE
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {orderData.fileList.map((file, index) => <tr key={index} className={classNames("border-b border-0 border-solid border-gray-200", isDarkMode ? "bg-gray-800 border-gray-700 hover:bg-gray-900 text-gray-200" : "bg-white hover:bg-gray-50")}>
                        <th scope="row" className="px-6 py-4 font-normal whitespace-nowrap">
                            <div className="flex items-center">
                                {file.path ? <img src={`${constants.SOCKET_URL}${file.path}`} alt="" className="mr-4 rounded w-16 h-16 object-cover" /> : <div className='inline-flex justify-center items-center bg-gray-200 shadow mr-4 rounded w-16 h-16'>
                                    <h1 className="m-0 font-bold text-orange-600">{index + 1}</h1>
                                </div>}
                                <div>
                                    {file.colorize && <p className='my-1'><span className="font-bold">Colorization:</span> Yes</p>}
                                    {file.print1 > 0 && <p className='my-1'><span className="font-bold">4x6 Print:</span> Yes</p>}
                                    {file.print2 > 0 && <p className='my-1'><span className="font-bold">5x7 Print:</span> Yes</p>}
                                    {file.print3 > 0 && <p className='my-1'><span className="font-bold">8x10 Print:</span> Yes</p>}
                                    {file.print4 > 0 && <p className='my-1'><span className="font-bold">11x14 Print:</span> Yes</p>}
                                    {file.print5 > 0 && <p className='my-1'><span className="font-bold">16x20 Print:</span> Yes</p>}
                                </div>
                            </div>
                        </th>
                        <td className="px-6 py-4 text-center">
                            {file.colorize && <p className='my-1'>1</p>}
                            {file.print1 > 0 && <p className='my-1'>{file.print1}</p>}
                            {file.print2 > 0 && <p className='my-1'>{file.print2}</p>}
                            {file.print3 > 0 && <p className='my-1'>{file.print3}</p>}
                            {file.print4 > 0 && <p className='my-1'>{file.print4}</p>}
                            {file.print5 > 0 && <p className='my-1'>{file.print5}</p>}
                        </td>
                        <td className="text-right px-6 py-4">
                            {file.colorize && <p className='my-1'>$9.99</p>}
                            {file.print1 > 0 && <p className='my-1'>${file.print1 * 10}</p>}
                            {file.print2 > 0 && <p className='my-1'>${file.print2 * 15}</p>}
                            {file.print3 > 0 && <p className='my-1'>${file.print3 * 24}</p>}
                            {file.print4 > 0 && <p className='my-1'>${file.print4 * 34}</p>}
                            {file.print5 > 0 && <p className='my-1'>${file.print5 * 49}</p>}
                        </td>
                    </tr>)}
                    <tr className={classNames("border-b-2 border-0 border-solid border-purple-700", isDarkMode ? "bg-gray-800 text-gray-200" : "bg-white")}>
                        <th scope="row" className="px-6 py-4 font-normal whitespace-nowrap">
                            <div>
                                { 
                                    !isMorePrints && 
                                        <p className='my-1'>
                                            <span className="font-bold">Photo restoration artist:</span>
                                            {orderData.artist == 'top' ? " Head Artist" : " Experienced Artist"}
                                        </p>
                                }
                                {
                                    !isMorePrints &&
                                        <p className='my-1'>
                                            <span className="font-bold">Processing speed:</span>
                                            {orderData.processingDelay == 0 && " 3 Days"}
                                            {orderData.processingDelay == 1 && " 24 Hours"}
                                        </p>
                                }
                                
                                {
                                    isShipped() && 
                                        <p className='my-1'>
                                            <span className="font-bold">Shipping speed:</span>
                                            { " " + SHIPPING_PRICING[orderData.delivery].title }
                                        </p>
                                }
                                {
                                    orderData.fileList.length > 2 && !isMorePrints &&
                                        <p className="my-1">
                                            <span className="font-bold">Discount: </span>
                                            { (orderData.fileList.length > 2 && orderData.fileList.length < 5) && 3 }
                                            { (orderData.fileList.length >= 5 && orderData.fileList.length < 10) && 5 }
                                            { (orderData.fileList.length >= 10) && 10 } Photos</p>}
                            </div>
                        </th>
                        <td className="px-6 py-4 text-center">
                            {
                                !isMorePrints &&
                                    <p className='my-1'>{orderData.fileList.length}</p>
                            }
                            {
                                !isMorePrints &&
                                    <p className='my-1'>{orderData.fileList.length}</p>
                            }
                            {
                                isShipped() && <p className='my-1'>{1}</p>
                            }
                            {
                                !isMorePrints && orderData.fileList.length > 2 &&
                                    <p className='my-1'>1</p>
                            } 
                        </td>
                        <td className="text-right px-6 py-4">
                            {
                                !isMorePrints &&
                                    <p className='my-1'>
                                        {
                                            orderData.coupon == 'static' && 
                                            <span className='line-through'>${orderData.fileList.length * 38}</span>
                                        } 
                                        ${ orderData.fileList.length * (orderData.coupon == "static" ? 22 : 38) }
                                    </p>
                            }
                            {
                                !isMorePrints &&
                                    <p className='my-1'>
                                        ${Number(orderData.fileList.length * (orderData.processingDelay > 0 ? Number(`${orderData.processingDelay - 1}9.99`) : 0)).toFixed(2)}
                                    </p>
                            }
                            {
                                isShipped() &&
                                <p className='my-1'>${Number(SHIPPING_PRICING[orderData.delivery].price).toFixed(2)}</p>
                            }
                            {
                                !isMorePrints && orderData.fileList.length > 2 &&
                                <p className="my-1">
                                    -${(orderData.fileList.length > 2 && orderData.fileList.length < 5) && 20}
                                    {(orderData.fileList.length >= 5 && orderData.fileList.length < 10) && 40}
                                    {(orderData.fileList.length >= 10) && 100}
                                </p>
                            }
                        </td>
                    </tr>
                    <tr className={classNames("", isDarkMode ? "bg-gray-800 text-gray-200" : "bg-white")}>
                        <th scope="row" className="px-6 py-4 whitespace-nowrap">
                            <h2 className='text-xl'>Total</h2>
                        </th>
                        <td className="px-6 py-4 text-center">
                        </td>
                        <td className="text-right px-6 py-4">
                            <h2 className='font-bold text-xl'>{orderData.coupon && <span className='font-bold line-through'>${totalPrice()[1]}</span>} <span className="text-orange-500">${totalPrice()[0]}</span></h2>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className={classNames('p-5 rounded-xl', isDarkMode ? "bg-gray-800" : "bg-purple-50")}>
                <div className='flex justify-between items-center cursor-pointer' onClick={() => setPayment('card')}>
                    <h3 className='flex items-center mb-0 text-sm md:text-lg lg:text-xl'>{payment == 'card' ? <FaRegCircleDot className='mr-2 text-orange-500' /> : <FaRegCircle className='mr-2' />} Credit/Debit Cards {payment == 'card' && `($${totalPrice()[0]})`}</h3>
                    <div>
                        <img src="/imgs/payments/amex.svg" className='m-1 w-8 md:w-10' alt="" />
                        <img src="/imgs/payments/discover.svg" className='m-1 w-8 md:w-10' alt="" />
                        <img src="/imgs/payments/visa.svg" className='m-1 w-8 md:w-10' alt="" />
                        <img src="/imgs/payments/mastercard.svg" className='m-1 w-8 md:w-10' alt="" />
                    </div>
                </div>
                {/* <Elements stripe={stripePromise} nonce="random-nonce"> */}
                {/* <CheckoutForm isComplete={!isShipped() || isComplete} form={form} /> */}
                {payment == 'card' && <div>
                    <CardElement
                        options={{
                            disableLink: true,
                            classes: {
                                base: "border border-solid py-3 px-4 rounded mt-4 border-gray-400 bg-white",
                                invalid: "border-red-500"
                            }
                        }}
                        // className={classNames("border border-solid py-3 px-4 rounded mt-4 border-gray-400 bg-white")}
                        onChange={(event) => {
                            if (event.error) {
                                setCardError(event.error.message);
                            } else {
                                setCardError(null);
                            }
                        }}
                    />
                    {cardError && (
                        <div className="text-red-500 text-sm">{cardError}</div>
                    )}
                    <div className="mt-6 text-center">
                        <Button
                            type="primary"
                            size='large'
                            htmlType='submit'
                            className='mb-4 rounded-none w-72 h-12'
                            icon={<LockOutlined />}
                            disabled={!stripe || cardError}
                            onClick={payNow}
                            block
                            loading={loading}
                        >PLACE MY ORDER</Button>
                    </div>
                </div>}
                {/* </Elements> */}
                <div className='flex justify-between items-center my-4 cursor-pointer' onClick={() => setPayment('paypal')}>
                    <h3 className='flex items-center mb-0 text-sm md:text-lg lg:text-xl'>{payment == 'paypal' ? <FaRegCircleDot className='mr-2 text-orange-500' /> : <FaRegCircle className='mr-2' />} Paypal</h3>
                    <div>
                        <img src="/imgs/payments/paypal.png" className='m-1 w-8 md:w-10' alt="" />
                    </div>
                </div>
                {payment == 'paypal' && <div className="mt-6 text-center">
                    <PayPalScriptProvider options={{ "client-id": constants.CLIENT_ID }}>
                        <PayPalButtons
                            style={{
                                layout: "vertical",
                                label: "pay",
                                height: 48
                            }}
                            fundingSource='paypal'
                            onClick={(data, actions) => {
                                if (isShipped() && !isComplete) {
                                    message.warning("Please fill all billing info!");
                                    return actions.reject();
                                }
                                return form.validateFields().then(async (values) => {
                                    console.log(values);
                                    return actions.resolve();
                                }).catch(err => {
                                    message.warning("Please fill all billing info!");
                                    return actions.reject();
                                });
                            }}
                            createOrder={createOrder}
                            onApprove={onApprove}
                        />
                    </PayPalScriptProvider>
                </div>}
            </div>
        </>
    );
}

export default PaymentProcess;

