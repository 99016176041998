import { all, call, put, takeLatest } from 'redux-saga/effects'

import {
  addCart,
  addCartFailure,
  addCartSuccess,
  removeCart,
  removeCartFailure,
  removeCartSuccess,
  getAllCarts,
  getAllCartsSuccess,
  getAllCartsFailure,
  setCount,
  setCountSuccess,
  setSetting,
  updateAttributes,
} from './cartSlice';
import { setStorage } from '../../helpers';

import { deleteRequest, getRequest, postRequest } from '../../services/axiosClient';
import { changeCount } from '../../services/cartAPI';

function* getAllCartsAPI(action) {
  try {
    const response = yield call(() => getRequest('carts/getAllCarts', action.payload));
    yield put(getAllCartsSuccess(response.data));
  } catch (e) {
    yield put(getAllCartsFailure());
  }
}

function* addCartAPI(action) {
  try {
    const response = yield call(() => postRequest('carts/addCart', action.payload));
    yield put(addCartSuccess(response.data));
  } catch (e) {
    yield put(addCartFailure());
  }
}

function* setCountAPI(action) {
  try {
    const response = yield call(() => changeCount(action.payload.id, { count: action.payload.count }));
    yield put(setCountSuccess(action.payload));
  } catch (e) {
    // yield put(addCartFailure());
  }
}

function* setSettingAPI(action) {
  try {
    const response = yield call(() => postRequest(`carts/setSetting/${action.payload.id}`, { setting: action.payload.setting }));
    // yield put(setCountSuccess(action.payload));
  } catch (e) {
    // yield put(addCartFailure());
  }
}

function* removeCartAPI(action) {
  try {
    const response = yield call(() => deleteRequest(`carts/removeCart/${action.payload}`));
    yield put(removeCartSuccess(response.data));
  } catch (e) {
    yield put(removeCartFailure());
  }
}

function* updateAttributesAPI(action) {
  try {
    yield call(() => postRequest(`carts/updateAttributes/${action.payload.id}`, {attributes: action.payload.attributes}));
  } catch (e) {
    console.log(e);
  }
}

export default function* rootSaga() {
  yield all([takeLatest(addCart, addCartAPI), takeLatest(removeCart, removeCartAPI), takeLatest(getAllCarts, getAllCartsAPI), takeLatest(setCount, setCountAPI), takeLatest(setSetting, setSettingAPI), takeLatest(updateAttributes, updateAttributesAPI)]);
}
