import { Card, Col, Row } from 'antd';
import React, { useState } from 'react';
import { SHIPPING_PRICING } from '../../../../config/constants';
import { CheckCircleTwoTone } from '@ant-design/icons';
import { BiSolidPurchaseTag } from 'react-icons/bi';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

const DeliveryOptions = ({initialDelivery, updateData}) => {
    
    const isDarkMode = useSelector(state => state.app.isDarkMode);
    const [delivery, setDelivery] = useState(initialDelivery);

    const updateDelivery = (key) => {
        setDelivery(key);
        updateData('delivery', key);
    }

    return (
        <Row gutter={[24, 24]} align={'center'}>
            {
                Object.keys(SHIPPING_PRICING).map((key) => (
                    <Col xs={24} sm={12} key={key}>
                        <Card
                            onClick={() => updateDelivery(key)}
                            className={
                                classNames('h-full cursor-pointer border-2 border-solid hover:shadow-lg relative',
                                    delivery === key && 'border-orange-500',
                                    delivery !== key && isDarkMode && "border-gray-700",
                                    delivery !== key && !isDarkMode && "border-gray-300",
                                )
                            }
                            styles={{ body: { height: '100%' } }}
                        >
                            <div className='top-5 md:right-5 left-5 md:left-auto absolute'>
                                <CheckCircleTwoTone className='text-lg' twoToneColor={delivery == key ? '#0F0' : '#AAA'} />
                            </div>
                            <div className='flex flex-col justify-between items-center pt-4 h-full'>
                                <h1 className='mb-1 font-semibold text-2xl */ /*'>
                                    {SHIPPING_PRICING[key].title}
                                </h1>
                                {/* <h1 className={ 
                              classNames('text-lg sm:text-lg md:text-xl font-bold my-3', 
                                delivery == key ? (isDarkMode ? 'text-gray-200' : 'text-gray-600') : (isDarkMode ? 'text-gray-400' : 'text-gray-400'))
                              }
                            >
                              { SHIPPING_PRICING[key].title }
                            </h1> */}
                                <span className='inline-flex items-center bg-green-600 mt-4 px-4 py-1 font-bold text-white'>
                                    <BiSolidPurchaseTag className='mr-1' />
                                    {SHIPPING_PRICING[key].price > 0 ? `$${SHIPPING_PRICING[key].price}` : 'Free'}
                                </span>
                                <span className='font-bold'>{dayjs().add(SHIPPING_PRICING[key].range[1], 'day').format('dd MMM D')}</span>
                            </div>
                        </Card>
                    </Col>
                ))
            }
        </Row>
    );
}

export default DeliveryOptions;







