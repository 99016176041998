import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  items: [],
}

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    getAllCarts(state, action) {
      state.loading = true;
    },
    getAllCartsSuccess(state, action) {
      state.loading = false;
      state.items = action.payload.carts;
    },
    getAllCartsFailure(state, action) {
      state.loading = false;
    },
    addCart(state, action) {
      state.loading = true;
    },
    addCartSuccess(state, action) {
      state.loading = false;
      state.items = [...state.items, action.payload.cart];
    },
    addCartFailure(state, action) {
      state.loading = false;
    },
    removeCart(state, action) {
      state.loading = true;
    },
    removeCartSuccess(state, action) {
      state.items = state.items.filter(item => item._id != action.payload.id);
      state.loading = false;
    },
    removeCartFailure(state, action) {
      state.loading = false;
    },
    setCount(state, action) {
    },
    setCountSuccess(state, action) {
      state.items[action.payload.index].quantity = action.payload.count;
    },
    updateAttributes(state, action) {
      let index = state.items.findIndex(item => item._id == action.payload.id);
      state.items[index].attributes = action.payload.attributes;
    },
    setCountSuccess(state, action) {
      state.items[action.payload.index].quantity = action.payload.count;
    },
    setSetting(state, action) {
      let index = state.items.findIndex(item => item._id == action.payload.id);
      state.items[index].setting = action.payload.setting;
    }
  },
});

export const {
  addCart,
  addCartSuccess,
  addCartFailure,
  removeCart,
  removeCartSuccess,
  removeCartFailure,
  getAllCarts,
  getAllCartsSuccess,
  getAllCartsFailure,
  setCount,
  setCountSuccess,
  setSetting,
  updateAttributes,
} = cartSlice.actions;
export default cartSlice.reducer;
