
const constants = {
  dev: {
    HOST_URL: 'http://localhost:5000/api/',
    stripePK: 'pk_test_51MqDHeIhTKltFnGq3WGCHjZi5ZfUg5T8aiGVPa7XFZcrse4SGLvTjDbXDNb5inKjlOaJqHJRDv5HsPQ6tbnfsxRL00rwetwKzu',
    SOCKET_URL: 'http://localhost:5000',
    CLIENT_ID: "ASaxzI3mYU8DcLeKFpFCsHgL-WaVAueiR67JVGueJxynrfsjt11p2N1YbpwLLAI-tRt0hTqKALK3Fgel",
    APP_URL: 'https://upload.fixphotos.ai',
    SERVER2: 'https://order.fixphotos.ai',
  },
  prod: {
    HOST_URL: process.env.REACT_APP_HOST_URL || '/api/',
    stripePK: 'pk_live_51O9RiuIjwMxrBR2FeBjBER32Jzcv9NFI0YvOe4we6UpNkf9FztbuckUBViOxpcmusFvJp9azz2aI5BPewtZNmVTc00yVZPmxPl',
    SOCKET_URL: '',
    CLIENT_ID: "AdBpIF71UzBr-tAUQHkmwRVGIsIswpm4i-rN0-oGLP2xf-pe1UBaN9oQnzVTfn-tHuSI-0Y3qXZ4ngq4",
    APP_URL: 'https://upload.fixphotos.ai',
    SERVER2: 'https://order.fixphotos.ai',
  }
};

const REACT_APP_ENV = process.env.REACT_APP_ENV || "dev";

export const ORDER_STATUS = {
  Restore: 1,
  PendingApprove: 2,
  Printing: 3,
  Shipping: 4,
  Complete: 5,
}

export const PHOTO_STATUS = {
  0: "Pending",
  1: "Revisions",
  2: "Approved",
  3: "Printing",
  4: "Shipping",
  5: "Complete",
}

export const PHOTO_STATE = {
  Revisions: 1,
  Approved: 2,
  Printing: 3,
  Shipping: 4,
  Complete: 5,
}

export const PRINT_SIZES = {
  print1: "4x6",
  print2: "5x7",
  print3: "8x10",
  print4: "11x14",
  print5: "16x20",
}

export const COLORS = ["#2FF3E0", "#F8D210", "#FA26A0", "#0000FF", "#81B622", "#f35b04", "#18A558"];

export const PRODUCTS = [
  {
    label: "Stretched canvas",
    img: "canvases.jpg",
    value: "stretched_canvas"
  },
  {
    label: "Framed Prints",
    img: "framed-prints_instagram-frames.jpg",
    value: "framed_prints"
  },
  {
    label: "Greetings cards",
    img: "stationery_greetings-cards.jpg",
    value: "greetings_cards"
  },
  // {
  //   label: "Unisex Hoodies",
  //   img: "apparel_unisex-hoodies.jpg",
  //   value: "unisex_hoodies"
  // },
  {
    label: "Phone cases",
    img: "technology.jpg",
    value: "phone_cases"
  },
  {
    label: "Magnets",
    img: "magnets.jpg",
    value: "magnets"
  },
  {
    label: "Stickers",
    img: "stickers.jpg",
    value: "stickers"
  },
  // {
  //   label: "Water Bottles",
  //   img: "sport-and-games_water-bottles.jpg",
  //   value: "water_bottles"
  // },
  {
    label: "Mugs",
    img: "home-and-living_mugs.jpg",
    value: "mugs"
  },
  // {
  //   label: "Bags",
  //   img: "home-and-living_bags.jpg",
  //   value: "bags"
  // },
  {
    label: "Acrylic Prism",
    img: "home-and-living_acrylic-prism.jpg",
    value: "acrylic_prism"
  },
  {
    label: "Mouse mats",
    img: "home-and-living_mouse-mats.jpg",
    value: "mouse_mats"
  },
  {
    label: "Postcards",
    img: "stationery_postcards.jpg",
    value: "postcards"
  },
  {
    label: "Wrapping paper",
    img: "stationery_wrapping-paper.jpg",
    value: "wrapping_paper"
  },
]

export const SHIPPING_PRICING = {
  free: {
    title: "8-11 Days",
    range: [8, 11],
    price: 0,
  },
  standard: {
    title: "5-7 Days",
    range: [5, 7],
    price: 10.50,
  },
  express: {
    title: "3-5 Days",
    range: [3, 5],
    price: 22.95,
  },
  overnight: {
    title: "1-2 Days",
    range: [1, 2],
    price: 30.95,
  },
}

export default constants[REACT_APP_ENV];
