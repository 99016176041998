import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const RedirectWithQuery = ({ to }) => {
  const location = useLocation();
  const navigatePath = `${to}${location.search}`; // Appends existing query string

  return <Navigate to={navigatePath} replace />;
};

export default RedirectWithQuery;