import { getRequest, postRequest, putRequest } from "./axiosClient";

export const saveOrder = (data) => postRequest('order/saveOrder', data);
export const getOrdersWithUserId = (id) => getRequest(`order/getOrdersWithUserId/${id}`);
export const updateOrder = (id, data) => postRequest(`order/updateOrder/${id}`, data);
export const rotatePhoto = (data) => postRequest("order/rotatePhoto", data);
export const cropPhoto = (data) => postRequest("order/cropPhoto", data);
export const resetPhoto = (data) => postRequest("order/resetPhoto", data);
export const sendFinishEmail = (id, query) => getRequest(`order/sendFinishEmail/${id}`, query);
export const sendWaterMarkEmail = (id, query) => getRequest(`order/sendWaterMarkEmail/${id}`, query);
export const getOrdersById = (id, query) => getRequest(`order/getOrderById/${id}`, query);
export const approveResult = (id, data) => postRequest(`order/approveResult/${id}`, data);
export const updateRequest = (id, data) => postRequest(`order/updateRequest/${id}`, data);
export const printingPackage = (id, data) => postRequest(`order/printingPackage/${id}`, data);
export const printingPhotoPackage = (data) => postRequest("order/printingPhotoPackage", data);
export const shippingPackage = (id, data) => postRequest(`order/shippingPackage/${id}`, data);
export const completeOrder = (id, data) => postRequest(`order/completeOrder/${id}`, data);
export const completeOrderManually = (id, data) => postRequest(`order/completeOrderManually/${id}`, data);
export const savePrintSetting = (id, data) => postRequest(`order/savePrintSetting/${id}`, data);
export const savePhotoDir = (id, data) => postRequest(`order/savePhotoDir/${id}`, data);
export const combineOrders = (id, data) => postRequest(`order/combineOrders/${id}`, data);