import React from 'react';
import { Button, Modal, Typography } from 'antd';

const { Text } = Typography;

const ApproveConfirmationModal = ({ isOpen, setIsOpen }) => {
    
    const onClose = () => {
        setIsOpen(false);
    }

    return (
        <Modal
            open={isOpen}
            onCancel={onClose}
            maskClosable={false}
            title={<Text className='text-xl'>Approve Confirmation</Text>}
            footer={[
                <>
                    <Button type="primary" 
                        onClick={() => {}}
                    >
                        Approve
                    </Button>
                    <Button
                        danger
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                </>
            ]}
        >
            <Text className='text-base'>Are you sure you want to approve this order?</Text>
        </Modal>
    );
}

export default ApproveConfirmationModal;