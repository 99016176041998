import React, { useEffect, useState } from 'react'
import { Avatar, Col, Divider, Form, Row, message } from 'antd';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import PublicLayout from '../../layouts/PublicLayout'
import { useSocket } from '../../../context/socket';
import { updateOrderDetail } from '../../../redux/order/orderSlice';

import { getOrdersById } from '../../../services/orderAPI';
// import useForm from '../../../Hooks/useForm';
import { checkPromo} from '../../../services/planAPI';
import Address from './Partials/Address';
import DeliveryOptions from './Partials/DeliveryOptions';
import PaymentProcess from './Partials/PaymentProcess';

// const stripePromise = loadStripe(constants.stripePK);
function Checkout() {

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const socket = useSocket();

  const { id } = useParams();
  const [form] = Form.useForm();  

  const isDarkMode = useSelector(state => state.app.isDarkMode);
  const orderData = useSelector(state => state.order);

  const [isComplete, setIsComplete] = useState(false);

  const onFinish = (values) => {
    // console.log(values);
  };

  useEffect(() => {
    (function (h, o, t, j, a, r) {
      h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
      h._hjSettings = { hjid: 4989381, hjsv: 6 };
      a = o.getElementsByTagName('head')[0];
      r = o.createElement('script'); r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');

    window.scrollTo(0, 0);
    if (id) {
      getOrder();
    }
  }, []);

  const getOrder = () => {
    getOrdersById(id).then((res) => {
      const { _id, createdAt, paymentId, refunded, status, updatedAt, userId, __v, ...rest } = res.data.order;
      if (paymentId) {
        message.warning("Already purchased!");
        navigate('/order/step1');
      } else {
        dispatch(updateOrderDetail({
          value: {
            orderId: _id,
            name: userId.name,
            email: userId.email,
            ...rest
          }
        }));
        form.setFieldsValue({
          name: userId.name,
          email: userId.email,
        });

        checkPromo(location.search).then(({ data }) => {
          updateData('coupon', data.coupon);
        }).catch(err => console.log(err));
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  useEffect(() => {
    if (!id && (orderData.fileList.length == 0 || !orderData.orderId)) {
      navigate('/order/step1');
    }
  }, [orderData]);

  const updateData = (field, value) => {
    dispatch(updateOrderDetail({ field, value }));
  }

  const isShipped = () => {
    return orderData.fileList.some(file => file.print1 > 0 || file.print2 > 0 || file.print3 > 0 || file.print4 > 0 || file.print5 > 0);
  }

  useEffect(() => {
    if (socket) {
      socket.on("charge.succeeded", async data => {
        message.success("Successsfully purchased!");
        if (data.orderId) {
          navigate(`/order/${data.orderId}/result`);
        } else {
          navigate('/order/success');
        }
      });
    }
    return () => {
      if (socket) {
        socket.off('charge.succeeded');
      }
    }
  }, [socket]);

  return (
    <PublicLayout>
      {/* <div className="relative bg-cover py-10 text-center" style={{ backgroundImage: `url(${bg})` }}>
        <div className={classNames("absolute top-0 left-0 w-full h-full", isDarkMode ? "bg-[#000B]" : "bg-[#FFFB]")}></div>
        <div className='relative'>
          <h1 className='my-5'>Checkout</h1>
          <div className='flex justify-center items-center md:hidden'>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 42 42" fill="none"><path d="M21 1.75146L35.3798 4.94696C36.1795 5.12546 36.75 5.83421 36.75 6.65496V24.1322C36.75 27.6427 34.9948 30.9222 32.074 32.8682L21 40.2515L9.92602 32.8682C7.00352 30.9205 5.25002 27.6427 5.25002 24.134V6.65496C5.25002 5.83421 5.82052 5.12546 6.62027 4.94696L21 1.75146ZM21 5.33721L8.75002 8.05846V24.1322C8.75002 26.472 9.91902 28.6577 11.8668 29.9562L21 36.0462L30.1333 29.9562C32.081 28.6577 33.25 26.4737 33.25 24.134V8.05846L21 5.33896V5.33721ZM28.791 14.39L31.2673 16.8645L20.1303 28.0015L12.705 20.5762L15.1795 18.1017L20.1285 23.0507L28.791 14.3882V14.39Z" fill="#F7780F"></path></svg>
            <p className='m-0 font-medium text-lg'>100% Money Back Guarantee</p>
          </div>
        </div>
      </div> */}
      <div className="mx-auto p-4 w-full max-w-4xl">
        <Row gutter={[24, 24]}>
          <Col xs={24} md={16}>
            <Form
              name="register"
              form={form}
              className="form"
              scrollToFirstError
              initialValues={{
                name: orderData.name,
                email: orderData.email,
              }}
              onFinish={onFinish}
            >
              <section>
                <h1 className="my-6 text-xl md:text-2xl lg:text-3xl">Billing details</h1>
                <Address 
                  isShipped={isShipped()}
                  setIsComplete={setIsComplete}
                  orderData={orderData}
                  updateData={updateData}
                />
              </section>

              {isShipped() && <section className="my-16">
                <h1 className="my-6 text-xl md:text-2xl lg:text-3xl">Delivery</h1>
                <DeliveryOptions 
                  initialDelivery={orderData.delivery}
                  updateData={updateData}
                />
              </section>}

              <section className='my-16'>
                <h1 className="my-6 text-xl md:text-2xl lg:text-3xl">Your order</h1>
                <div className="bg-purple-700 p-0.5"></div>
                <h1 className="my-4 text-lg md:text-xl lg:text-2xl">Your Photo Restoration <span className="text-orange-500">x {orderData.fileList.length}</span></h1>
                <PaymentProcess 
                  orderData={orderData} 
                  isComplete={isComplete} 
                  form={form}
                />
              </section>
              {/* <section className={classNames("my-16 rounded-xl p-5", isDarkMode ? "bg-gray-900" : "bg-purple-50 shadow")}>
          <div className="flex items-center">
            <img src="/imgs/upsell-deskt-preview-300x300.png" alt="" className="shadow mr-4 md:mr-6 w-28 md:w-32" />
            <div>
              <h2 className="text-lg md:text-xl lg:text-2xl">Would you like to add a set of prints?</h2>
              <p className='my-1'>Add a set of one 8x10 & two 5x7 archival photo prints for each photo. We print your photos on museum-quality photo paper backed by our 100 year no-fade guarantee. <span className="font-bold text-orange-500">Only $87</span></p>
            </div>
          </div>
          <div className='mt-5 md:ml-32 text-center md:text-left'><Button shape='round' type='primary' className='mr-2' icon={<CheckCircleFilled style={{ color: true ? 'orange' : '#888' }} />}>Yes, please add a set of prints!</Button></div>
        </section> */}

            </Form>

            <section className="my-16">
              <div className={classNames("p-5 max-w-3xl mx-auto rounded-xl", isDarkMode ? "bg-gray-900" : "bg-gray-50")}>
                <div className="flex justify-center items-center">
                  <img src="/imgs/Vector.png" className='mx-1 md:mx-2' alt="" />
                  <p className={classNames("text-[11px] sm:text-sm font-bold my-0 mx-1 md:mx-2 text-center", isDarkMode ? "text-gray-400" : "text-gray-500")}>
                    FREE<br />SHIPPING
                  </p>
                  <img src="/imgs/Group-1.png" className='mx-1 md:mx-2' alt="" />
                  <p className={classNames("text-[11px] sm:text-sm font-bold my-0 mx-1 md:mx-2 text-center", isDarkMode ? "text-gray-400" : "text-gray-500")}>
                    100%<br />Money Back
                  </p>
                  <img src="/imgs/Vector-1.png" className='mx-1 md:mx-2' alt="" />
                  <p className={classNames("text-[11px] sm:text-sm font-bold my-0 mx-1 md:mx-2 text-center", isDarkMode ? "text-gray-400" : "text-gray-500")}>
                    SECURE<br />PAYMENT
                  </p>
                </div>
                <Divider>GUARANTEED SAFE CHECKOUT</Divider>
                <div className="flex justify-around items-center">
                  {/* <img className='px-1 w-10 sm:w-auto' src="/imgs/payments/McAfee.png" alt="" /> */}
                  <img className='px-1 w-10 sm:w-auto' src="/imgs/payments/stripe.png" alt="" />
                  <img className='px-1 w-10 sm:w-auto' src="/imgs/payments/PayPal_logo.png" alt="" />
                  <img className='px-1 w-10 sm:w-auto' src="/imgs/payments/visacard.png" alt="" />
                  <img className='px-1 w-10 sm:w-auto' src="/imgs/payments/master-card.png" alt="" />
                  <img className='px-1 w-10 sm:w-auto' src="/imgs/payments/american-p.png" alt="" />
                  {/* <img className='px-1 w-10 sm:w-auto' src="/imgs/payments/discpver.png" alt="" /> */}
                </div>
              </div>
            </section>
          </Col>
          <Col xs={24} md={8}>
            <section className='my-8'>
              <div className={classNames('hidden md:block p-5 rounded-xl text-white', isDarkMode ? "from-orange-700 to-orange-500" : "from-orange-600 to-orange-400")}>
                {/* <h1 className="mb-0 font-bold text-2xl text-center md:text-3xl lg:texl-4xl homenaje-regular">
                  100% <span className='text-lg md:text-xl lg:text-2xl'>★ ★ ★ ★ ★</span> <br />
                  MONEY <br />
                  BACK <br />
                  <span className='text-xl md:text-2xl lg:text-3xl'>GUARANTEE</span>
                </h1> */}
                <img src="/imgs/guaranteed_img.png" alt="img" className='w-full' />
              </div>
            </section>
            <section className='my-16'>
              <div className={classNames("rounded-lg p-6 text-center", isDarkMode ? "bg-gray-900" : "bg-purple-50 shadow")}>
                <div className='-mt-12 text-center'>
                  <img src="/imgs/test-cot-icon.png" alt="" />
                </div>
                <p className='mt-4'>"We’re living in the future! I had an old faded black and white photo of my grandparents wedding, and the full color version is mind-blowing."</p>
                <span className='text-lg text-orange-500'>★ ★ ★ ★ ★</span>
                <div>
                  <Avatar src="/imgs/david.webp" /> <span className='font-bold'>- Mark C.</span>
                </div>
              </div>
            </section>
            <section className='my-16'>
              <div className={classNames("rounded-lg p-6 text-center", isDarkMode ? "bg-gray-900" : "bg-purple-50 shadow")}>
                <div className='-mt-12 text-center'>
                  <img src="/imgs/test-cot-icon.png" alt="" />
                </div>
                <p className='mt-4'>"OMG! I never thought i’d be able to fix this photo of my mother from her childhood. This is amazing, the photo is brand new! I’m very impressed!"</p>
                <span className='text-lg text-orange-500'>★ ★ ★ ★ ★</span>
                <div>
                  <Avatar src="/imgs/ashley.webp" /> <span className='font-bold'>- Ashley T.</span>
                </div>
              </div>
            </section>
            <section className='my-16'>
              <div className={classNames("rounded-lg p-6 text-center", isDarkMode ? "bg-gray-900" : "bg-purple-50 shadow")}>
                <div className='-mt-12 text-center'>
                  <img src="/imgs/test-cot-icon.png" alt="" />
                </div>
                <p className='mt-4'>"I wanted to surprise my father with a photograph that was taken before he went to war. The photo was badly cracked and faded – now the photo is as good as new!"</p>
                <span className='text-lg text-orange-500'>★ ★ ★ ★ ★</span>
                <div>
                  <Avatar src="/imgs/jason.webp" /> <span className='font-bold'>- Jason M.</span>
                </div>
              </div>
            </section>
            <section className='my-16'>
              <div className="flex justify-around">
                <div className='text-center'>
                  <h4>As seen on</h4>
                  <div>
                    <img className="bg-white mx-1 rounded-full" src="/imgs/ABC.png" alt="" />
                    <img className="mx-1" src="/imgs/NBC.png" alt="" />
                    <img className="mx-1" src="/imgs/Fox-News.png" alt="" />
                  </div>
                </div>
                {/* <div className='text-center'>
                  <h4>Trusted by</h4>
                  <div>
                    <img className="mx-1" src="/imgs/Group-654.png" alt="" />
                  </div>
                </div> */}
              </div>
            </section>
          </Col>
        </Row>
      </div>
    </PublicLayout>
  )
}

export default Checkout