import React, { useEffect } from "react";
import { Button, Divider, Layout, Menu } from "antd";
import classNames from "classnames";
import { SiTrustpilot } from "react-icons/si";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { UserAddOutlined, UnlockOutlined, MoonFilled, MailOutlined, StarFilled } from "@ant-design/icons";
import Settings from "./partials/Settings";
import { getPromo } from "../../helpers";

// import smLogoSrc from "../../assets/images/logo-sm.png";
// import LogoSrc from "../../assets/images/logo.png";
// import { setDarkMode } from "../../redux/app/appSlice";
// import { clearErrors } from "../../redux/auth/authSlice";
const { Header, Content, Footer } = Layout;

const items = [
  {
    label: "Login",
    key: "/login",
    icon: <UnlockOutlined />,
  },
  {
    label: "Register",
    key: "/register",
    icon: <UserAddOutlined className="ml-2" />,
  },
];

function PublicLayout({ children }) {

  const isDarkMode = useSelector(state => state.app.isDarkMode);
  const orderData = useSelector(state => state.order);

  useEffect(() => {
    (function (h, o, t, j, a, r) {
      h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
      h._hjSettings = { hjid: 4989381, hjsv: 6 };
      a = o.getElementsByTagName('head')[0];
      r = o.createElement('script'); r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');

  }, []);

  return (
    <Layout>
      {/*<Sider/>*/}
      {orderData.coupon == 'static' && <div className="bg-yellow-400 p-1.5 text-center">:🎉 Restoration price is <span className="line-through">$38</span><span className="font-bold"> $22</span></div>}
      {getPromo(orderData.coupon) != 1 && <div className="bg-yellow-400 p-1.5 text-center">:🎉 You will save <span className="font-bold">{((1 - getPromo(orderData.coupon)) * 100).toFixed()}%</span> on your purchase!</div>}
      <Layout>
        <Header className={classNames(isDarkMode ? "bg-black" : "bg-white", "px-2 h-auto")}>
          <div className="flex items-center justify-between max-w-5xl mx-auto flex-wrap">
            <div className="demo-logo">
              <Link to="/order/step1" className="hidden sm:inline">
                <img src={`/imgs/logo${isDarkMode ? '-dark' : ''}.webp`} alt="logo" className="h-[64px] p-3" />
              </Link>
              <Link to="/order/step1" className="inline sm:hidden">
                <img src="/imgs/logo-sm.png" alt="logo" className="w-[64px] p-3" />
              </Link>
            </div>
            <div className="flex items-center bg-white px-4 text-black font-bold leading-8">
              <span className="text-[16px]">Excellent</span>
              <div className="mx-2 flex">
                <SiTrustpilot className="text-[16px] text-white bg-[#00b67a] mx-[1px] p-0.5" />
                <SiTrustpilot className="text-[16px] text-white bg-[#00b67a] mx-[1px] p-0.5" />
                <SiTrustpilot className="text-[16px] text-white bg-[#00b67a] mx-[1px] p-0.5" />
                <SiTrustpilot className="text-[16px] text-white bg-[#00b67a] mx-[1px] p-0.5" />
                <SiTrustpilot className="text-[16px] text-white bg-[#00b67a] mx-[1px] p-0.5" />
              </div>
              <div className="inline-flex items-center"><SiTrustpilot className="text-[#00b67a] text-lg mr-1" /> <span>Trustpilot</span></div>
            </div>
          </div>
          <div className={classNames("flex items-center max-w-5xl mx-auto rounded-xl leading-7 px-0 sm:px-10 py-3 mt-4 shadow", isDarkMode ? "bg-white" : "bg-black")}>
            <div className="ml-4 mx-2 sm:mx-6">
              <StarFilled className="text-yellow-500 p-0.5 pt-1 text-sm sm:text-xl" />
              <StarFilled className="text-yellow-500 p-0.5 pt-1 text-sm sm:text-xl" />
              <StarFilled className="text-yellow-500 p-0.5 pt-1 text-sm sm:text-xl" />
              <StarFilled className="text-yellow-500 p-0.5 pt-1 text-sm sm:text-xl" />
              <StarFilled className="text-yellow-500 p-0.5 pt-1 text-sm sm:text-xl" />
            </div>
            <h3 className={classNames("m-0 text-sm sm:text-[15px]", isDarkMode ? "text-black" : "text-white")}>
              Rated 4.9/5 <span className="font-normal">by 15k+ happy customers</span>
            </h3>
          </div>
        </Header>
        <Content
          style={{ minHeight: "calc(100vh - 136px)" }}
          className={classNames(isDarkMode ? "bg-" : "bg-white")}
        >
          {children}
        </Content>
      </Layout>
      <Settings />
      <Footer className={classNames("px-4 sm:px-6 bg-gray-900 text-gray-400", isDarkMode ? "" : "")}>
        <div className="max-w-5xl mx-auto text-center px-5">
          <img src="/imgs/trust.png" className="max-w-36 w-full" alt="" />
          <br />
          <br />
          <a href="mailto:hello@prophotos.ai"><MailOutlined /> Need help? <b>Let us know!</b></a>
          <br />
          <img src="/imgs/logo-dark.webp" className="max-w-44 w-full my-6" alt="" />
          <p className="max-w-xl mx-auto">FixPhotos.AI is the #1 photo restoration studio. We turn your old photos into brand new memories that can last generations.</p>
          <p className="">You'll love the attention to detail we put into our restorations, and the quality of our printed photos!</p>
          <Divider className="bg-gray-400" />
          <div className="flex justify-between items-center">
            <p className="m-0">© 2024 FixPhotos.AI</p>
            <p className="m-0">Terms & Privacy</p>
          </div>
        </div>
      </Footer>
    </Layout>
  );
}

export default PublicLayout;
